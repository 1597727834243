import { OpenedHours, Settings, WeekDayEnum } from "../types/Settings";
import { allWeekDays as allWeekDaysInPtBr } from "./formatters";

const dayAsWeekDay = (dt = new Date()) => {
    return dt.toLocaleString('en-US', {
        timeZone: 'America/Sao_Paulo',
        weekday: 'long',
    }).toLowerCase() as WeekDayEnum;
}

const getTimes = (openedHour: OpenedHours): [number, number, number] => {
    const strTimeNow = new Date().toLocaleTimeString('pt-BR', {
        timeZone: 'America/Sao_Paulo',
    });

    const [timeNow, startTime, endTime] = [
        strTimeNow,
        openedHour.startTime,
        openedHour.endTime,
    ].map(x => {
        const [hour, minute, seconds] = x.split(':');
        return ((Number(hour) * 60 * 60) + (Number(minute) * 60) + Number(seconds)) * 1000;
    });

    return [timeNow, startTime, endTime];
}

export const storeIsOpen = (settings: Settings | undefined | null): boolean => {
    if (!settings)
        return false;

    const weekDaysOpenedHours = settings.openedHours;

    const weekDay = dayAsWeekDay();
    const openedHours = weekDaysOpenedHours.find(x => x.weekday === weekDay);
    if (!openedHours)
        return false

    const [timeNow, startTime, endTime] = getTimes(openedHours);

    return timeNow >= startTime && timeNow <= endTime;
};

const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

const allWeekDays = [
    WeekDayEnum.sunday,
    WeekDayEnum.monday,
    WeekDayEnum.tuesday,
    WeekDayEnum.wednesday,
    WeekDayEnum.thursday,
    WeekDayEnum.friday,
    WeekDayEnum.saturday,
]

const lstDaysStartingFromToday = (): WeekDayEnum[] => {
    const today = dayAsWeekDay();
    const index = allWeekDays.indexOf(today);

    return allWeekDays.slice(index).concat(allWeekDays.slice(0, index));
}

export const nextChangeOnOpenState = (settings: Settings): number => {
    const weekDaysOpenedHours = settings.openedHours;

    const refWeekDays = lstDaysStartingFromToday();
    const today = dayAsWeekDay();

    let daysSinceToday = 0;
    for (const weekDay of refWeekDays) {
        const openedHours = weekDaysOpenedHours.find(x => x.weekday === weekDay);
        if (!openedHours) {
            daysSinceToday++;
            continue;
        }

        const [timeNow, startTime, endTime] = getTimes(openedHours);

        if (today !== weekDay)
            return (startTime - timeNow) + (daysSinceToday * oneDayInMilliseconds);

        if (timeNow <= startTime)
            return startTime - timeNow;

        if (timeNow <= endTime)
            return endTime - timeNow;

        daysSinceToday++;
    }

    throw new Error('store-will-be-closed-indefinitely')
}

export const formatNextChangeOnOpenState = (settings: Settings | null): string => {
    if (!settings)
        return '';

    const nextChange = nextChangeOnOpenState(settings);

    const days = Math.floor(nextChange / oneDayInMilliseconds);
    const hours = Math.floor((nextChange % oneDayInMilliseconds) / (60 * 60 * 1000));
    const minutes = Math.floor((nextChange % (60 * 60 * 1000)) / (60 * 1000));
    const seconds = Math.floor((nextChange % (60 * 1000)) / 1000);

    const targetDate = new Date(Date.now() + nextChange);

    if (days > 1) {
        const day = dayAsWeekDay(targetDate);
        return `${allWeekDaysInPtBr[day].toLowerCase()} as ${targetDate.getHours()}hrs`;
    }

    if (days > 0)
        return `amanhã as ${targetDate.getHours()}hrs`

    if (hours > 1) {
        return `as ${targetDate.getHours()}hrs`
    }

    if (hours > 0)
        return `em ${hours} hora`;

    if (minutes > 0)
        return `em ${minutes} minuto${minutes > 1 ? 's' : ''}`;

    if (seconds > 0)
        return `em ${seconds} segundo${seconds > 1 ? 's' : ''}`;

    return '';
}