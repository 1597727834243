import { FunctionComponent, ReactNode, useCallback } from "react";
import { IconProps, VoidCallback } from "../../../types";
import ErrorIcon from "../../../assets/images/icone-find.png";
import styles from './address-option.module.scss'
import { Placeholder } from "../../utils/placeholder";
import { TrashIcon } from "../../utils/icon/trash";

interface PropsPredefinedAddress {
    title: string | ReactNode;
    subtitle: string | ReactNode;
    onClick?: VoidCallback;
    isError?: boolean;
    Icon: FunctionComponent<IconProps>;
    isLoading?: boolean;
    isSelected?: boolean;
    onRemove?: VoidCallback;
}

export const AddressOption: FunctionComponent<PropsPredefinedAddress> = props => {
    const styleError = props.isError ? styles.isError : '';
    const styleActive = props.isSelected ? styles.selected : '';
    const styleRemovable = !!props.onRemove ? styles.isRemovable : '';
    const styleIsLoading = props.isLoading ? styles.isLoading : '';
    const stylesIsClickable = !!props.onClick ? styles.isClickable : '';

    const handleClick = useCallback(() => {
        if (props.isLoading || !props.onClick)
            return;

        props.onClick();
    }, [props]);

    const style = [
        styles.addressOption,
        styleError,
        styleActive,
        styleRemovable,
        styleIsLoading,
        stylesIsClickable
    ].join(' ');

    return (
        <div
            className={style}
            onClick={!props.onRemove ? props.onClick : undefined}>
            {!props.isError && (
                <props.Icon onClick={!!props.onRemove ? handleClick : undefined} className={styles.icon}/>)}
            {props.isError && (<img className={styles.icon} src={ErrorIcon} alt='Out of area'/>)}

            {props.isLoading && (
                <Placeholder width={240} height={36}/>
            )}
            {!props.isLoading && (
                <div className={styles.text} onClick={!!props.onRemove ? handleClick : undefined}>
                    <span>{props.title}</span>
                    <span>{props.subtitle}</span>
                </div>
            )}

            {props.onRemove && (
                <div className={styles.remove} onClick={props.onRemove}>
                    <TrashIcon className={styles.icon}/>
                </div>
            )}
        </div>
    );
};