import { FunctionComponent, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { chooseDeliveryMethod, getChosenAddress, getChosenDeliveryMethod } from '../../../store/addressSlice';
import { DeliveryMethodEnum } from '../../../types/Order';
import styles from './missing-address-badge.module.scss';
import { Button } from '../../utils/button';
import ErrorLocation from '../../../assets/images/icone-find.png';

export const MissingAddressBadge: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const address = useAppSelector(getChosenAddress);
    const deliveryMethod = useAppSelector(getChosenDeliveryMethod);

    const choosePickUp = useCallback(() => {
        dispatch(chooseDeliveryMethod(DeliveryMethodEnum.pickup));
    }, [dispatch]);

    if (deliveryMethod === DeliveryMethodEnum.pickup || !!address) {
        return null;
    }

    return (
        <div className={styles.missingAddressBadge}>
            <img src={ErrorLocation} alt="Error on location" />
            <span>Um endereço deve ser escolhido.</span>
            <Button label="Retirar na loja" onClick={choosePickUp} />
        </div>
    );
};
