import { FunctionComponent, useEffect, useRef, useState } from "react";
import styles from './cart.module.scss'
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getCartIsOpened, setCartIsOpened } from "../../store/appSlice";
import { CloseIcon } from "../utils/icon/close";
import { TextButton } from "../utils/text-button";
import { cleanShoppingCart, getSelectedProduct } from "../../store/cartSlice";
import useKeypress from "../../hooks/useKeyPress";
import useOnClickOutside from "../../hooks/useOnclickOutside";
import { MinimumPurchaseBadge } from "./minimum-purchase-badge";
import { ClosedStoreBadge } from "./store-closed-badge";
import { DiscountCouponField } from "./discount-coupon-field";
import { ProductsCart } from "./products-cart";
import { ActionCart } from "./action-cart";
import { MissingAddressBadge } from "./missing-address-badge";

export const Cart: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const ref = useRef(null);
    const isOpened = useAppSelector(getCartIsOpened);
    const selectedCartProduct = useAppSelector(getSelectedProduct);
    const [isOpening, setIsOpening] = useState(false);

    const closeCart = () => {
        setIsOpening(false);
        setTimeout(() => {
            dispatch(setCartIsOpened(false));
        }, 400);
    };
    const cleanCart = () => {
        dispatch(cleanShoppingCart());
        dispatch(setCartIsOpened(false));
    };

    useOnClickOutside(ref, () => {
        if (!isOpened)
            return;

        closeCart();
    });

    useKeypress('Escape', () => {
        if (!isOpened)
            return;

        closeCart();
    }, []);

    useEffect(() => {
        if (!isOpened)
            return;

        setIsOpening(true);
    }, [isOpened]);

    if (!isOpened || !!selectedCartProduct)
        return null;

    const openingStyle = isOpening ? styles.opening : '';
    return (
        <div className={styles.cartBackground}>
            <div className={`${styles.cart} ${openingStyle}`} ref={ref}>
                <div className={styles.header}>
                    <CloseIcon onClick={closeCart} className={styles.closeIcon}/>
                    <h5>Seu carrinho</h5>
                    <TextButton label='Limpar carrinho' onClick={cleanCart}/>
                </div>

                <div className={styles.cartContent}>
                    <MinimumPurchaseBadge/>
                    <ClosedStoreBadge/>
                    <MissingAddressBadge/>
                    <ProductsCart/>
                    <DiscountCouponField/>
                </div>

                <ActionCart/>
            </div>
        </div>
    )
}

