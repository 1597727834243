import { useAppDispatch, useAppSelector } from "../../hooks";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Dialog } from "../utils/dialog";
import { getProductsInCart, getSelectedProduct, unselectCartProduct, upsertCartProduct } from "../../store/cartSlice";
import StorageService from "../../services/StorageService";
import styles from './selected-order-product.module.scss';
import { Button } from "../utils/button";
import { formatPriceAmount } from "../../utils/formatters";
import { OrderProduct } from "../../types/Order";
import {VoidCallback} from "../../types";

interface Props {
    runAfterUpdate?: VoidCallback
}

export const SelectedOrderProduct: FunctionComponent<Props> = ({runAfterUpdate}) => {
    const dispatch = useAppDispatch();
    const selectedProduct = useAppSelector(getSelectedProduct);
    const lstCart = useAppSelector(getProductsInCart);

    const [quantity, setQuantity] = useState(1);
    const [option, setOption] = useState<string>();
    const [observation, setObservation] = useState<string>()

    const increaseQuantity = useCallback(() => {
        setQuantity(quantity + 1);
    }, [quantity]);

    const decreaseQuantity = useCallback(() => {
        if (quantity === 1)
            return;

        setQuantity(quantity - 1);
    }, [quantity]);

    const btnDecreaseStyle = quantity === 1 ? styles.disabledQuantityController : '';

    const handleAddProduct = useCallback(() => {
        if (!selectedProduct)
            return;

        const obj: OrderProduct = {
            product: selectedProduct.product,
            quantity,
            option,
            observation
        }

        dispatch(upsertCartProduct(obj));
        dispatch(unselectCartProduct());
        if (!!runAfterUpdate)
            runAfterUpdate()


    }, [dispatch, observation, option, quantity, selectedProduct]);

    useEffect(() => {
        setQuantity(selectedProduct?.quantity || 1);
        setOption(selectedProduct?.option);
        setObservation(selectedProduct?.observation);
    }, [selectedProduct]);

    const onClose = useCallback(() => {
        setQuantity(1);
        setOption(undefined);
        setObservation(undefined);
        dispatch(unselectCartProduct())
    }, [dispatch])

    if (!selectedProduct)
        return null;

    const total = selectedProduct.product.price * quantity;

    const isValidOption = !selectedProduct.product.options?.length || option;
    const btnDisabled = !isValidOption;

    const alreadyInCart = lstCart.some(x => x.product.id === selectedProduct?.product.id);
    const labelBtn = alreadyInCart ? 'Atualizar' : `Adicionar ${formatPriceAmount(total)}`;

    return (
        <Dialog
            isOpen={!!selectedProduct}
            title={selectedProduct.product.name}
            className={styles.selectedOrderProduct}
            onClose={onClose}>
            <div className={styles.content}>
                <div className={styles.image}>
                    <img
                        src={StorageService.getUrl(selectedProduct.product.idImage)}
                        alt={selectedProduct.product.name}/>
                </div>
                <p className={styles.description}>
                    {selectedProduct.product.description}
                </p>
                <h5>{selectedProduct.product.quantity}</h5>
                <h5>{formatPriceAmount(selectedProduct.product.price)}</h5>
                {!!selectedProduct.product.options?.length && (
                    <div className={styles.options}>
                        <h4>Opções</h4>
                        <div className={styles.optionsList}>
                            {selectedProduct.product.options?.map(x => (
                                <span
                                    key={x}
                                    className={x === option ? styles.selected : ''}
                                    onClick={() => setOption(x)}>{x}</span>
                            ))}
                        </div>
                    </div>
                )}
                <div className={styles.observation}>
                    <h4>Observação</h4>
                    <textarea
                        value={observation}
                        onChange={e => setObservation(e.target.value)}
                        placeholder="Ex: Tirar a cebola"/>
                </div>
            </div>
            <div className={styles.footer}>
                <div className={styles.quantityController}>
                    <span onClick={decreaseQuantity} className={btnDecreaseStyle}>-</span>
                    <span>{quantity}</span>
                    <span onClick={increaseQuantity}>+</span>
                </div>
                <Button
                    label={labelBtn}
                    onClick={handleAddProduct}
                    disabled={btnDisabled}/>
            </div>
        </Dialog>
    )
}