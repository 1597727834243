import { ReservationCoupon } from '../types/DiscountCoupon';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from './index';
import { getShippingPrice } from './addressSlice';
import { getSubTotalPrice } from './cartSlice';
import { DiscountCouponService } from '../services/DiscountCouponService';
import { getErrorMessage } from '../utils/errorMessage';

interface DiscountCouponState {
    isLoading: boolean;
    reservation: ReservationCoupon | null;
    errorReservation: string | null;
}

const initialState: DiscountCouponState = {
    isLoading: false,
    reservation: null,
    errorReservation: null
};

const discountCouponSlice = createSlice({
    name: 'discountCoupon',
    initialState,
    reducers: {
        setReservation: (state, action: PayloadAction<ReservationCoupon | null>) => {
            state.reservation = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setErrorReservation: (state, action: PayloadAction<string | null>) => {
            state.errorReservation = action.payload;
        }
    }
});

const { setReservation, setIsLoading, setErrorReservation } = discountCouponSlice.actions;

export const applyCoupon = (key: string): AppThunk => (dispatch, getState) => {
    const shippingPrice = getShippingPrice(getState());
    const subtotalValue = getSubTotalPrice(getState());

    dispatch(setIsLoading(true));

    DiscountCouponService.reserveDiscountCoupon({ key, shippingPrice, subtotalValue })
        .then(x => {
            dispatch(setReservation(x));
            dispatch(setIsLoading(false));
        })
        .catch(err => {
            dispatch(setErrorReservation(getErrorMessage(err.message)));
            dispatch(setIsLoading(false));
        });
};

export const cleanDiscountCouponReservation = (): AppThunk => dispatch => {
    dispatch(setReservation(null));
};

export const getDiscountCouponIsLoading = (root: RootState) => root.discountCoupon.isLoading;
export const getDiscountCouponReservation = (root: RootState) => root.discountCoupon.reservation;
export const getDiscountCouponErrorReservation = (root: RootState) => root.discountCoupon.errorReservation;

export default discountCouponSlice.reducer;
