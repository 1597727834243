import React, { FunctionComponent } from 'react';
import { useAppDispatch } from '../../../hooks';
import { CopyIcon } from '../icon/copy';
import { showSnack } from '../../../store/notificationSlice';

interface Props {
    label: string;
    value: string | number;
    styleWeight?: 'default' | 'slim';
    className?: string;
}

export const CopyButton: FunctionComponent<Props> = props => {
    const dispatch = useAppDispatch();
    const handleClick = async () => {
        await navigator.clipboard.writeText(props.value.toString());
        dispatch(showSnack(`${props.label} copiado com sucesso`));
    };

    return <CopyIcon onClick={handleClick} className={props.className} />;
};
