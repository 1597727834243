import { FunctionComponent, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
    getStoreOpenedChangeStatusPopup,
    setCartIsOpened,
    setStoreOpenedChangeStatusPopup
} from '../../../store/appSlice';
import { Dialog } from '../../utils/dialog';
import { useStoreIsOpen } from '../../../hooks/useStoreIsOpen';
import ClosedStoreIcon from '../../../assets/images/closed-store.png';
import OpenedStoreIcon from '../../../assets/images/opened-store.png';
import { Button } from '../../utils/button';
import { getProductsInCart } from '../../../store/cartSlice';
import styles from './store-opened-poup.module.scss';
import { useNavigate } from 'react-router-dom';

export const StoreOpenedPopup: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const popupIsOpened = useAppSelector(getStoreOpenedChangeStatusPopup);
    const lstProducts = useAppSelector(getProductsInCart);

    const openIfIsClosed = useCallback(() => {
        if (popupIsOpened)
            return;

        dispatch(setStoreOpenedChangeStatusPopup(true));
    }, [dispatch, popupIsOpened]);

    const [storeIsOpened] = useStoreIsOpen(openIfIsClosed);

    const closePopup = useCallback(() => {
        dispatch(setStoreOpenedChangeStatusPopup(false));
        navigate('/');
    }, [dispatch, navigate]);

    const goToStore = useCallback(() => {
        dispatch(setStoreOpenedChangeStatusPopup(false));
        dispatch(setCartIsOpened(true));
    }, [dispatch]);

    return (
        <Dialog isOpen={popupIsOpened} onClose={closePopup} className={styles.storeOpenedPopup}>
            {storeIsOpened && (
                <>
                    <h3>Loja acabou de abrir</h3>
                    <img src={OpenedStoreIcon} alt="Opened Store" />
                    <p>Uhuuuul... A loja acabou de abrir. Ja pode fazer o seu pedido</p>
                    {!!lstProducts.length && (
                        <Button label="Ir para o carrinho" onClick={goToStore} />
                    )}
                </>
            )}

            {!storeIsOpened && (
                <>
                    <h3>Loja acabou de fechar 😢</h3>
                    <img src={ClosedStoreIcon} alt="Closed store" />
                    <p>Que pena a loja acabou de fechar</p>
                    <Button label="Continuar navegando" onClick={closePopup} />
                </>
            )}
        </Dialog>
    );
};
