import { FunctionComponent } from "react";
import styles from "./store-closed-badge.module.scss";
import { useStoreIsOpen } from "../../../hooks/useStoreIsOpen";

export const ClosedStoreBadge: FunctionComponent = () => {
    const [isOpened, formattedWhenWillOpen] = useStoreIsOpen();

    if (isOpened)
        return null;

    return (
        <div className={styles.closedStoreBadge}>
            <span>😢&nbsp;&nbsp;Infelizmente a loja está fechada</span>
            <span>Abrimos {formattedWhenWillOpen}</span>
        </div>
    )
}