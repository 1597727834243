import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { delay, upsertOnList } from '../utils';
import { AppThunk, AppThunkDispatcher, RootState } from './index';
import { Websocket } from '../services/Websocket';
import {
    removeOnLocalStorage,
    retrieveFromLocalStorage,
    saveLastUpdatedDate,
    saveOnLocalStorage
} from '../utils/storage';
import { CreateOrderDto, Order, StatusEnum } from '../types/Order';
import OrderService from '../services/OrderService';

const ORDERS_KEY = 'orders';
const ORDERS_LAST_UPDATED_DATE_KEY = 'orders_last_updated';

interface OrderState {
    lstOrders: Order[];
    selectedOrderId: string | null;
    isLoadingOrder: boolean;
}

const initialState: OrderState = {
    lstOrders: [],
    selectedOrderId: null,
    isLoadingOrder: false
};

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        upsertOrder: (state, action: PayloadAction<Order>) => {
            state.lstOrders = upsertOnList(state.lstOrders, action.payload);
        },
        cleanListOrders: state => {
            state.lstOrders = [];
        },
        setIsLoadingOrder: (state, action: PayloadAction<boolean>) => {
            state.isLoadingOrder = action.payload;
        },
        setSelectedOrderId: (state, action: PayloadAction<string | null>) => {
            state.selectedOrderId = action.payload;
        },
    }
});

const {
    upsertOrder,
    cleanListOrders,
    setIsLoadingOrder,
} = orderSlice.actions;

export const {
    setSelectedOrderId
} = orderSlice.actions;

export const createOrder = (order: CreateOrderDto): AppThunk<Promise<void>> => async (dispatch, getState) => {
    dispatch(setIsLoadingOrder(true));

    OrderService.createOrder(order)
        .then((obj) => {
            dispatch(setIsLoadingOrder(false));
            dispatch(setSelectedOrderId(obj.id));
        })
        .catch(err => {
            dispatch(setIsLoadingOrder(false));
            throw err;
        });
};


export const setupOrderState = (): AppThunk => (dispatch, getState) => {
    loadFromStorage(dispatch);

    Websocket.onEvent<Order>('update-order', order => {
        dispatch(upsertOrder(order));
        return updateOnStorage(getState);
    });

    Websocket.emitOnLogin('subscribe-orders', ORDERS_LAST_UPDATED_DATE_KEY);
};

const updateOnStorage = async (getState: () => RootState) => {
    await delay(100);

    const orders = getState().order.lstOrders;
    saveOnLocalStorage(ORDERS_KEY, orders);
    saveLastUpdatedDate(ORDERS_LAST_UPDATED_DATE_KEY, orders);
};

const loadFromStorage = (dispatch: AppThunkDispatcher) => {
    const lst = retrieveFromLocalStorage<Order[]>(ORDERS_KEY);
    lst?.forEach(order => dispatch(upsertOrder(order)));
};

export const cleanOrderStateAndStorage = (): AppThunk => dispatch => {
    dispatch(cleanListOrders());
    removeOnLocalStorage(ORDERS_KEY);
    removeOnLocalStorage(ORDERS_LAST_UPDATED_DATE_KEY);
};

export const getSortedOrders = (states?: StatusEnum[]) => (root: RootState): Order[] => {
    const parsedLst = root.order.lstOrders;

    const lst = parsedLst.filter(x => !states || states.includes(x.status));

    return lst.sort((a, b) => a.createdAt.getTime() > b.createdAt.getTime() ? 1 : -1);
};

export const getOrder = (id: string | undefined) => (root: RootState): Order | undefined =>
    root.order.lstOrders.find(x => x.id === id);

export const getOrders = (root: RootState): Order[] => root.order.lstOrders;

export const getFilteredOrders = (root: RootState): Order[] => root.order.lstOrders.filter(x => x.status !== StatusEnum.pending);

export const getSelectedOrder = (root: RootState): Order | undefined =>
    root.order.lstOrders.find(x => x.id === root.order.selectedOrderId);

export const getOrderIsLoading = (root: RootState): boolean => root.order.isLoadingOrder;

export default orderSlice.reducer;
