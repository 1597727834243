import { VoidCallback } from "../../../types";
import { FunctionComponent, ReactNode, useCallback, useEffect, useState } from "react";
import { Field } from "../../utils/field";
import { PinLocationIcon } from "../../utils/icon/pin-location";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
    chooseSearchResult,
    getFetchLocationIsLoading,
    getPlacesOutOFDeliveryZone,
    getSearchIsLoading,
    getSearchResponses,
    searchLocations
} from "../../../store/addressSlice";
import { SpinLoading } from "../../utils/spin-loading";
import { AddressOption } from "../address-option";
import { SearchLocationResponseItem } from "../../../types/location";
import styles from './search-address.module.scss';
import { trunkText } from "../../../utils/formatters";

interface Props {
    requestToConfirm: VoidCallback;
}

export const SearchAddress: FunctionComponent<Props> = props => {
    const dispatch = useAppDispatch();

    const [query, setQuery] = useState('');
    const [chosenPlaceId, setChosenPlaceId] = useState<string | undefined>("EmdBdi4gTWFyY29zIFBlbnRlYWRvIGRlIFVsaMO0YSBSb2RyaWd1ZXMgLSBSZXNpZGVuY2lhbCBUcmVzIChUYW1ib3JlKSwgU2FudGFuYSBkZSBQYXJuYcOtYmEgLSBTUCwgQnJhc2lsIi4qLAoUChIJfzhHjFgCz5QR-R_YFFZfPnQSFAoSCb8D5sH1As-UEWSScvaS8NBi");

    const results = useAppSelector(getSearchResponses)
    const isLoading = useAppSelector(getSearchIsLoading);
    const fetchIsLoading = useAppSelector(getFetchLocationIsLoading);

    const lstOutOfArea = useAppSelector(getPlacesOutOFDeliveryZone);

    useEffect(() => {
        const typingTimer = setTimeout(() => {
            if (!query || query.length < 3)
                return;
            dispatch(searchLocations(query));
        }, 500);

        return () => clearTimeout(typingTimer);
    }, [dispatch, query]);

    const loadingSuffix = isLoading ? <SpinLoading/> : undefined;

    const handleChosenResult = useCallback((item: SearchLocationResponseItem) => async () => {
        try {
            setChosenPlaceId(item.placeId);
            await dispatch(chooseSearchResult(item))
            props.requestToConfirm();
        } catch (err) {
            setChosenPlaceId(undefined)
        }
    }, [dispatch, props])

    const renderItem = (item: SearchLocationResponseItem): ReactNode => {
        const isLoading = fetchIsLoading && chosenPlaceId === item.placeId;
        const isOutOfArea = lstOutOfArea.some(x => x === item.placeId);

        return <AddressOption
            key={item.placeId}
            title={isOutOfArea ? "Não atendemos nesse endereço" : item.mainText}
            subtitle={isOutOfArea ? trunkText(50, item.mainText, item.secondaryText) : item.secondaryText}
            onClick={handleChosenResult(item)}
            isLoading={isLoading}
            isError={isOutOfArea}
            Icon={PinLocationIcon}/>
    }

    return (
        <div className={styles.searchAddress}>
            <Field
                className={styles.field}
                placeholder="Buscar endereço e número"
                onChange={setQuery}
                prefix={<PinLocationIcon/>}
                suffix={loadingSuffix}/>

            <div className={styles.searchAddressOptions}>
                {results.map(renderItem)}
            </div>
        </div>
    )
}