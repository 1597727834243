import { AnnotationEnum, CategoryEnum } from '../types/Product';
import { DeliveryMethodEnum, Order, PaymentMethodEnum, StatusEnum, StatusPaymentEnum, ToGoOrder } from '../types/Order';
import { WeekDayEnum } from '../types/Settings';
import { AddressInfo } from '../types/Address';
import { BasicUser } from '../types';
import { extractNumber } from './index';

export const allWeekDays: Record<WeekDayEnum, string> = {
    [WeekDayEnum.sunday]: 'Domingo',
    [WeekDayEnum.monday]: 'Segunda',
    [WeekDayEnum.tuesday]: 'Terça',
    [WeekDayEnum.wednesday]: 'Quarta',
    [WeekDayEnum.thursday]: 'Quinta',
    [WeekDayEnum.friday]: 'Sexta',
    [WeekDayEnum.saturday]: 'Sábado'
};

export const allAnnotations: Record<AnnotationEnum, string> = {
    [AnnotationEnum.smoked]: 'Smoked',
    [AnnotationEnum.spicy]: 'Apimentado',
    [AnnotationEnum.lactose]: 'Lactose'
};
export const formatAnnotation = (annotation?: AnnotationEnum): string => {
    if (!annotation)
        return '';

    return allAnnotations[annotation];
};

export const allCategories: Record<CategoryEnum, string> = {
    [CategoryEnum.entries]: 'Entradas',
    [CategoryEnum.sideDishes]: 'Acompanhamentos',
    [CategoryEnum.smokedMeets]: 'Defumados',
    [CategoryEnum.grilled]: 'Grelhados',
    [CategoryEnum.burgers]: 'Hamburguers',
    [CategoryEnum.sandwiches]: 'Sanduíches',
    [CategoryEnum.sauces]: 'Molhos',
    [CategoryEnum.salads]: 'Saladas',
    [CategoryEnum.deserts]: 'Sobremesas',
    [CategoryEnum.beverages]: 'Bebidas'
};

export const formatCategory = (category: CategoryEnum): string => allCategories[category];

export const phoneFormatter = (phoneNumber: string): string => {
    const num = phoneNumber.replace(/^55/, '');
    return `(${num.slice(0, 2)}) ${num.slice(2, 3)} ${num.slice(3, 7)}-${num.slice(-4)}`;
};
export const getTotalValue = (order: Order): string => {
    const subTotal = order.products
        .reduce((a, b) => a + (b.quantity * b.product.price), 0);

    const shippingPrice = (order as ToGoOrder).area?.deliveryFee || 0;
    const couponValue = order.discountCoupon?.discountValue || 0;
    const result = subTotal + shippingPrice - couponValue;

    return formatPriceAmount(result);
};

export const formatPriceAmount = (n: number | undefined): string => {
    const format = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });
    return format.format(n || 0);
};

const formatPaymentStatus = (status: StatusPaymentEnum): string => {
    return {
        [StatusPaymentEnum.paid]: 'Pago',
        [StatusPaymentEnum.pending]: 'Pendente'
    }[status];
};
export const formatPaymentInfo = (obj: Order) => {
    return {
        [PaymentMethodEnum.pix]: `${formatPaymentStatus(obj.statusPayment)} via pix`,
        [PaymentMethodEnum.creditCard]: `${formatPaymentStatus(obj.statusPayment)} via cartão de crédito`,
        [PaymentMethodEnum.cash]: 'Pagamento na entrega'
    }[obj.paymentMethod];
};

export const formatStatus = (status: StatusEnum, deliveryMethod: DeliveryMethodEnum): string => {
    return {
        [StatusEnum.pending]: 'Pendente',
        [StatusEnum.inPreparation]: 'Em preparo',
        [StatusEnum.readyToDeliver]: {
            [DeliveryMethodEnum.delivery]: 'Pronto para motoboy',
            [DeliveryMethodEnum.pickup]: 'Pronto para retirada'
        }[deliveryMethod],
        [StatusEnum.inTransit]: 'Em trânsito',
        [StatusEnum.delivered]: 'Entregue'
    }[status];
};

export const formatStatusOrder = (obj: Order): string =>
    formatStatus(obj.status, obj.deliveryMethod);

export const formatDeliveryMethod = (obj: Order): string => {
    return {
        [DeliveryMethodEnum.delivery]: 'Retirada no balcão',
        [DeliveryMethodEnum.pickup]: 'Entrega no endereço'
    }[obj.deliveryMethod];
};

export const formatDeliveryInfo = (obj: Order): string => {
    if (obj.deliveryMethod === DeliveryMethodEnum.pickup)
        return 'Retirada no balcão';

    return `${obj.address.streetLine}, ${obj.address.number} ${obj.address.complement}`;
};

export const formatAddress = (address: AddressInfo): string => {
    const { streetLine, number, complement } = address;
    return `${streetLine}, ${number} ${complement || ''}`;
};

export const trunkText = (maxLength: number, ...text: string[]): string => {
    const holeText = text.join(' ');
    return holeText.length > maxLength ? `${holeText.slice(0, maxLength)}...` : holeText;
};

export const formatSecondLineAddress = (address: AddressInfo): string => {
    const { city, neighborhood } = address;
    return `${neighborhood}, ${city}`;
};

export const formatFullAddress = (address: AddressInfo) => {
    return `${formatAddress(address)} - ${formatSecondLineAddress(address)}`;
};

export const capitalize = (text: string): string => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};

export const formatFirstName = (user: BasicUser | undefined | null): string => {
    if (!user)
        return '';

    const firstName = user.name.split(' ')[0];
    return capitalize(firstName);
};

export const numberCardInputFormatter = (number: string | null | undefined): string => {
    if (!number) return '';
    const num = extractNumber(number);

    //61985891092
    const slice = (start: number, end?: number): string => num.slice(start, end);
    const firstBloc = slice(0, 4);
    const secondBloc = slice(4, 8);
    const thirdBloc = slice(8, 12);
    const fourthBloc = slice(12);

    if (!!fourthBloc.length)
        return `${firstBloc} ${secondBloc} ${thirdBloc} ${fourthBloc}`;

    if (!!thirdBloc.length)
        return `${firstBloc} ${secondBloc} ${thirdBloc}`;

    if (!!secondBloc.length)
        return `${firstBloc} ${secondBloc}`;

    return firstBloc;
};

export const expirationInputFormatter = (expiration: string | null | undefined): string => {
    if (!expiration) return '';
    const num = extractNumber(expiration);

    const slice = (start: number, end?: number): string => num.slice(start, end);
    const firstBloc = slice(0, 2);
    const secondBloc = slice(2);

    if (!!secondBloc.length)
        return `${firstBloc}/${secondBloc}`;

    return firstBloc;
};

export const postalCodeInputFormatter = (postalCode: string | null | undefined): string => {
    if (!postalCode) return '';

    const num = extractNumber(postalCode);

    const slice = (start: number, end?: number): string => num.slice(start, end);
    const firstBloc = slice(0, 5);
    const secondBloc = slice(5, 8);

    if (!!secondBloc.length)
        return `${firstBloc}-${secondBloc}`;

    return firstBloc;
};

export const formatOrderId = (order: Order): string =>
    `#${order.id.toString().slice(-6)}`;

export const formatDiscountCoupon = (order: Order): string => {
    if (!order.discountCoupon)
        return '';

    return `${order.discountCoupon.key} (${formatPriceAmount(order.discountCoupon.discountValue)})`;
};

export const formatShippingPrice = (order: ToGoOrder): string => {
    if (!order.area)
        return '';

    return formatPriceAmount(order.area.deliveryFee);
};
