import { FunctionComponent } from 'react';
import { IconProps } from '../../../types';

export const DoneOutlineIcon: FunctionComponent<IconProps> = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={props.height || 24}
            onClick={props.onClick}
            className={!!props.onClick ? `${props.className} clickable-icon` : props.className}
            viewBox="0 -960 960 960"
            width={props.width || 24}>
            <path d="m381-240 424-424-57-56-368 367-169-170-57 57 227 226Zm0 113L42-466l169-170 170 170 366-367 172 168-538 538Z" />
        </svg>
    );
};
