import styles from './profile.module.scss';
import { AppBar } from '../app/app-bar';
import { EditPersonalData } from './edit-personal-data';
import { Orders } from './orders';
import { OutlineButton } from '../utils/outline-button';
import { useCallback } from 'react';
import { useAppDispatch } from '../../hooks';
import { cleanLocalStorageAndLogoff } from '../../store/appSlice';
import { useNavigate } from 'react-router-dom';

export const Profile = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleLogout = useCallback(async () => {
        navigate('/');
        await dispatch(cleanLocalStorageAndLogoff());
    }, [dispatch, navigate]);

    return (
        <>
            <AppBar />
            <div className={styles.profile}>
                <h2>Seus dados</h2>
                <EditPersonalData />
                <h2>Ultimos pedidos</h2>
                <Orders />

                <div className={styles.actions}>
                    <OutlineButton label="Sair" onClick={handleLogout} />
                </div>
                <div className={styles.version}>Versão: {process.env.REACT_APP_VERSION}</div>
            </div>
        </>
    );
};

