import { FunctionComponent } from 'react';
import { VoidCallback } from '../../../types';
import { EditIcon } from '../icon/edit';
import styles from './label.module.scss';

interface Props {
    label: string;
    value: string;
    onEdit?: VoidCallback;
}

export const Label: FunctionComponent<Props> = ({ label, value, onEdit }) => {
    return (
        <div className={styles.label}>
            <div className={styles.content}>
                <span>{label}</span>
                <span>{value}</span>
            </div>
            {onEdit && (
                <EditIcon className={styles.editIcon} onClick={onEdit} />
            )}
        </div>
    );
};
