import { useAppSelector } from '../../hooks';
import { getSelectedOrder } from '../../store/orderSlice';
import { useParams } from 'react-router-dom';
import styles from './order.module.scss';
import { AppBar } from '../app/app-bar';
import {
    formatDeliveryMethod,
    formatDiscountCoupon,
    formatFullAddress,
    formatOrderId,
    formatPaymentInfo,
    formatShippingPrice,
    formatStatusOrder,
    getTotalValue
} from '../../utils/formatters';
import { Label } from '../utils/label';
import { DeliveryMethodEnum, PixPaymentOrder, StatusEnum, ToGoOrder } from '../../types/Order';
import { CleanAppBar } from '../app/clean-app-bar';
import { OrderProduct } from './order-product';
import { StatusRoadMap } from './status-road-map';
import QrCode from 'react-qr-code';
import { CopyButton } from '../utils/copy-button';

interface BasicInfo {
    label: string;
    value: string;
}

export const Order = () => {
    const { id } = useParams();

    const orderFromParameter = useAppSelector(state => state.order.lstOrders.find(x => x.id === id));
    const selectedOrder = useAppSelector(getSelectedOrder);

    const order = orderFromParameter || selectedOrder;

    if (!order)
        return null;

    let basicInfo: BasicInfo[] = [];

    const addInfo = (label: string, value: string) => {
        basicInfo = [...basicInfo, { label, value }];
    };

    addInfo('Data do Pedido', order.createdAt.toLocaleString());

    if (!!order.discountCoupon)
        addInfo('Cupom de desconto', formatDiscountCoupon(order));

    if (order.deliveryMethod === DeliveryMethodEnum.pickup)
        addInfo('Forma de entrega', formatDeliveryMethod(order));

    addInfo('Status Pagamento', formatPaymentInfo(order));
    addInfo('Status do pedido', formatStatusOrder(order));

    if (order.deliveryMethod === DeliveryMethodEnum.delivery) {
        const toGoOrder = order as unknown as ToGoOrder;

        addInfo('Taxa de entrega', formatShippingPrice(toGoOrder));
        addInfo('Endereço', formatFullAddress(toGoOrder.address));
    }

    const CustomAppBar = order.status === StatusEnum.pending ? CleanAppBar : AppBar;
    const pendingStyle = order.status === StatusEnum.pending ? styles.pending : '';
    return (
        <>
            <CustomAppBar />

            <div className={`${styles.order} ${pendingStyle}`}>
                <h1>Pedido {formatOrderId(order)}</h1>

                <div className={styles.basicInfo}>
                    {basicInfo.map(({ label, value }, i) => (
                        <Label key={i} label={label} value={value} />
                    ))}
                </div>

                {order.status === StatusEnum.pending && !!(order as unknown as PixPaymentOrder).pixQrCode && (
                    <div className={styles.qrCode}>
                        <h2>
                            Pix QR Code
                            <CopyButton
                                label="Pix QrCode"
                                className={styles.copyButton}
                                value={(order as unknown as PixPaymentOrder).pixQrCode} />
                        </h2>
                        <QrCode value={(order as unknown as PixPaymentOrder).pixQrCode} />
                        <h2>{getTotalValue(order)}</h2>
                    </div>
                )}

                <StatusRoadMap order={order} className={styles.statusRoadMap} />

                <div className={styles.productsSection}>
                    <h2>Produtos</h2>
                    <div className={styles.products}>
                        {order.products.map(x => (
                            <OrderProduct key={x.product.id} data={x} />
                        ))}
                    </div>
                </div>

                <div className={styles.totalSection}>
                    <h2>Total</h2>
                    <span>{getTotalValue(order)}</span>
                </div>
            </div>
        </>
    );
};
