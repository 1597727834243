import styles from './placeholder.module.scss';
import { CSSProperties, FunctionComponent } from 'react';

interface Props{
    width: number;
    height?: number;
}

export const Placeholder: FunctionComponent<Props> = (props) => {
    const style: CSSProperties = {
        width: `${props.width}px`,
        height: props.height ? `${props.height}px` : '80px'
    }
    return <div className={styles.placeholder} style={style}></div>
}
