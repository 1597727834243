import { FunctionComponent, useCallback } from 'react';
import styles from './action-cart.module.scss';
import { Button } from '../../utils/button';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getSubTotalPrice } from '../../../store/cartSlice';
import { getChosenAddress, getChosenDeliveryMethod, getShippingPrice } from '../../../store/addressSlice';
import { DeliveryMethodEnum } from '../../../types/Order';
import { getDiscountCouponReservation } from '../../../store/discountCouponSlice';
import { formatPriceAmount } from '../../../utils/formatters';
import { getTotal } from '../../../utils/getTotal';
import { useStoreIsOpen } from '../../../hooks/useStoreIsOpen';
import { useMinimumPurchase } from '../../../hooks/useMinimumPurchase';
import { useNavigate } from 'react-router-dom';
import { setCartIsOpened } from '../../../store/appSlice';

export const ActionCart: FunctionComponent = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const subTotal = useAppSelector(getSubTotalPrice);
    const deliveryMethod = useAppSelector(getChosenDeliveryMethod);
    const chosenAddress = useAppSelector(getChosenAddress);
    const discountCoupon = useAppSelector(getDiscountCouponReservation);
    const shippingPrice = useAppSelector(getShippingPrice);

    const [isMinimumPurchaseValid] = useMinimumPurchase();
    const [opened] = useStoreIsOpen();

    const handleClick = useCallback(() => {
        dispatch(setCartIsOpened(false));
        navigate('/checkout');
    }, [dispatch, navigate]);

    let lines: [string, number][] = [];
    if (!!shippingPrice) {
        lines = [...lines, ['Taxa de entrega', shippingPrice]];
    }
    if (!!discountCoupon) {
        lines = [...lines, ['Desconto', discountCoupon.discountValue]];
    }

    lines = [...lines, ['Subtotal', subTotal]];

    const total = useAppSelector(getTotal);

    const deliveryIsValid = deliveryMethod === DeliveryMethodEnum.pickup || !!chosenAddress;
    const btnDisabled = !subTotal || !opened || !isMinimumPurchaseValid || !deliveryIsValid;

    return (
        <div className={styles.actionCart}>
            <div className={styles.subTotals}>
                {lines.map(([label, value], i) => (
                    <div key={i}>
                        <span>{label}</span>
                        <span>{formatPriceAmount(value)}</span>
                    </div>
                ))}
            </div>
            <div className={styles.action}>
                <div className={styles.total}>
                    <span>Total</span>
                    <span>{formatPriceAmount(total)}</span>
                </div>
                <Button label="Pagamento" onClick={handleClick} disabled={btnDisabled} />
            </div>
        </div>
    );
};

