import styles from './cart-summary.module.scss';
import {DiscountCouponField} from '../../cart/discount-coupon-field';
import {ProductsCheckout} from '../products-checkout';
import {useAppSelector} from '../../../hooks';
import {getSubTotalPrice} from '../../../store/cartSlice';
import {getShippingPrice} from '../../../store/addressSlice';
import {getDiscountCouponReservation} from '../../../store/discountCouponSlice';
import {getTotal} from '../../../utils/getTotal';
import {formatPriceAmount} from '../../../utils/formatters';
import {useCallback} from "react";
import {useNavigate} from "react-router-dom";


export const CartSummary = () => {

    const subTotal = useAppSelector(getSubTotalPrice);
    const shippingPrice = useAppSelector(getShippingPrice);
    const coupon = useAppSelector(getDiscountCouponReservation);
    const navigate = useNavigate();

    const total = useAppSelector(getTotal);

    let lines: [string, number][] = [
        ['Subtotal', subTotal]
    ];

    if (!!shippingPrice)
        lines = [...lines, ['Taxa de entrega', shippingPrice]];

    if (!!coupon)
        lines = [...lines, ['Desconto', coupon.discountValue]];

    const handleClick = useCallback(() => {
        navigate('/');
    }, [navigate]);
    return (

        <div className={styles.cartSummary}>

            <div className={styles.summaryTitle}>
                <h2>Resumo do pedido</h2>
            </div>
            <ProductsCheckout/>
            <div className={styles.addMoreItems}>
                <span onClick={handleClick}>Adicionar mais itens</span>
            </div>
            <DiscountCouponField/>
            <div className={styles.priceSummary}>
                {lines.map(([label, value], i) => (
                    <div key={i}>
                        <span>{label}</span>
                        <span>{formatPriceAmount(value)}</span>
                    </div>
                ))}
                <div>
                    <span>Total</span>
                    <span>{formatPriceAmount(total)}</span>
                </div>
            </div>
        </div>
    );
};
