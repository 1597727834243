import WhatsappLogo from '../../../assets/images/whatsapp-logo.svg';
import styles from './whatsapp.module.scss';
import { useCallback } from 'react';
import { useAppSelector } from '../../../hooks';
import { getSettings } from '../../../store/settingsSlice';

export const Whatsapp = () => {
    const settings = useAppSelector(getSettings);

    const onClick = useCallback(() => {
        window.open(`https://wa.me/${settings!.contacts.whatsapp}`, '_blank', 'noopener noreferrer');
    }, [settings]);

    if (!settings)
        return null;

    return (
        <div>
            <div className={styles.whatsapp} onClick={onClick}>
                <img src={WhatsappLogo} alt="Whatsapp" />
            </div>
        </div>
    );
};
