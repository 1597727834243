import LogoImage from '../../assets/images/logo.png';
import OwnerImage from '../../assets/images/butcher.png';
import EntrecoteImage from '../../assets/images/entrecotet.png';
import CuttingBoardImage from '../../assets/images/cutting-board.png';
import CleaverImage from '../../assets/images/cleaver.png';
import styles from './sign-in-or-up.module.scss';
import { FieldPhoneNumber } from '../utils/field-phone-number';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { TextButton } from '../utils/text-button';
import { useStepper } from '../../hooks/useStepper';
import { Button } from '../utils/button';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
    createClient,
    getErrorCreateClient,
    getIsLoginLoading,
    getLoginState,
    login,
    LoginStateEnum,
    sendTwoFactorCode, setErrorCreateClient
} from '../../store/authSlice';
import { FieldWithLabel } from '../utils/field-with-label';
import { isEmailValid } from '../../utils';
import { CreateClientDto } from '../../types/Client';
import { ArrowBackIcon } from '../utils/icon/arrow-back';
import { FieldMasked } from '../utils/field-masked';
import useKeypress from '../../hooks/useKeyPress';

export const SignInOrSignUp = () => {
    const [step, increaseSte, decreaseStep] = useStepper();
    const [phoneNumber, setPhoneNumber] = useState('');

    const error = useAppSelector(getErrorCreateClient);


    const openCreateAccount = useCallback((phoneNumber?: string) => {
        increaseSte();
        if (!!phoneNumber)
            setPhoneNumber(phoneNumber);
    }, [increaseSte]);

    const openSignIn = useCallback((phoneNumber?: string) => {
        decreaseStep();

        if (!!phoneNumber)
            setPhoneNumber(phoneNumber);
    }, [decreaseStep]);

    const { Form, title } = [
        { Form: () => <SignIn phoneNumber={phoneNumber} openCreateAccount={openCreateAccount}/>, title: 'Login' },
        { Form: () => <SignUp phoneNumber={phoneNumber} openSignIn={openSignIn}/>, title: 'Criar conta' }
    ][step];

    const errorStyle = !!error ? styles.error : undefined;

    return (
        <div className={`${styles.signInOrUp} `}>
            <div className={styles.logo}>
                <img src={LogoImage} alt="Logo"/>
            </div>

            <img alt="Butcher" src={OwnerImage} className={styles.ownerImage}/>
            <img alt="Entrecote" src={EntrecoteImage} className={styles.entrecoteImage}/>
            <img alt="Cutting board" src={CuttingBoardImage} className={styles.cuttingBoardImage}/>
            <img alt="Cleaver" src={CleaverImage} className={styles.cleaverImage}/>

            <div className={`${styles.form} ${errorStyle}`}>
                <div className={styles.header}>
                    {!!step && (<ArrowBackIcon onClick={decreaseStep} className={styles.backIcon}/>)}
                    <h3>{title}</h3>
                </div>
                <Form/>
                {!!error && (
                    <span className={styles.errorLabel}>{error}</span>
                )}
            </div>
        </div>
    );
};

interface SignInProps {
    phoneNumber?: string;
    openCreateAccount: (phoneNumber?: string) => void;
}

const SignIn: FunctionComponent<SignInProps> = props => {
    const dispatch = useAppDispatch();

    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [twoFactorAuthCode, setTwoFactorAuthCode] = useState<string>('');

    const isLoading = useAppSelector(getIsLoginLoading);
    const state = useAppSelector(getLoginState);
    const error = useAppSelector(getErrorCreateClient);

    useEffect(() => {
        if (!props.phoneNumber)
            return;

        setPhoneNumber(props.phoneNumber);
    }, [props.phoneNumber]);

    const sendCode = useCallback(async () => {
        if (!phoneNumber)
            return;

        try {
            await dispatch(sendTwoFactorCode(phoneNumber));
            setErrorCreateClient(null)
        } catch (err: any) {
            if (err.message === 'client-not-found')
                props.openCreateAccount(phoneNumber);
        }
    }, [dispatch, phoneNumber, props]);

    const loginClient = useCallback(async () => {
        if (!phoneNumber || !twoFactorAuthCode)
            return;

        if (!!error)
            return;

        await dispatch(login({ phoneNumber, twoFactorAuth: twoFactorAuthCode }));
        setPhoneNumber('');
        setTwoFactorAuthCode('');

    }, [dispatch, phoneNumber, twoFactorAuthCode]);


    useKeypress('Enter', () => {
        const valid = {
            [LoginStateEnum.willDoBasicAuth]: !!phoneNumber,
            [LoginStateEnum.willDoTwoFactorAuth]: !!phoneNumber && !!twoFactorAuthCode
        }[state];

        if (!valid)
            return;

        const handler = {
            [LoginStateEnum.willDoBasicAuth]: sendCode,
            [LoginStateEnum.willDoTwoFactorAuth]: loginClient
        }[state];

        handler();
    }, []);

    if (state === LoginStateEnum.willDoBasicAuth)
        return (
            <>
                <FieldPhoneNumber value={phoneNumber} onChange={setPhoneNumber}/>
                <Button
                    className={styles.actionButton}
                    isLoading={isLoading}
                    disabled={!phoneNumber}
                    label="Enviar código"
                    onClick={sendCode}/>

                <TextButton
                    className={styles.createAccountButton}
                    label="Criar conta" onClick={() => props.openCreateAccount()}/>
            </>
        );

    return (
        <>
            <FieldMasked
                value={twoFactorAuthCode}
                onChange={setTwoFactorAuthCode}
                label="Código enviado"
                placeHolder="142312"
                formatter={x => x}
                validator={x => x.length === 6}/>


            <Button
                label="Entrar"
                onClick={loginClient}
                className={styles.actionButton}
                disabled={!phoneNumber || !twoFactorAuthCode}
                isLoading={isLoading}/>
        </>
    );
};

interface SignUpProps {
    phoneNumber?: string;
    openSignIn: (phoneNumber?: string) => void;
}

const SignUp: FunctionComponent<SignUpProps> = props => {
    const dispatch = useAppDispatch();

    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const isLoading = useAppSelector(getIsLoginLoading);
    const error = useAppSelector(getErrorCreateClient);

    const btnDisabled = !phoneNumber
        || !name
        || name.trim().split(' ').length < 2
        || !isEmailValid(email);

    useEffect(() => {
        if (!props.phoneNumber)
            return;

        setPhoneNumber(props.phoneNumber);
    }, [props.phoneNumber]);

    const createAccount = useCallback(() => {
        if (btnDisabled)
            return;

        const dto: CreateClientDto = {
            phoneNumber,
            email: email.trim(),
            name: name.trim()
        };

        dispatch(createClient(dto));

        if (!error)
            return;

        props.openSignIn(phoneNumber);

    }, [btnDisabled, dispatch, email, name, phoneNumber, props]);


    return (
        <>
            <FieldPhoneNumber value={phoneNumber} onChange={setPhoneNumber} className={styles.field}/>

            <FieldWithLabel
                label="Nome completo"
                placeHolder="Nome completo"
                value={name}
                onChange={setName}
                className={styles.field}/>

            <FieldWithLabel
                label="Email"
                placeHolder="marcos@gmail.com"
                value={email}
                onChange={setEmail}
                className={styles.field}/>


            <Button
                className={styles.actionButton}
                isLoading={isLoading}
                disabled={btnDisabled}
                label="Criar conta"
                onClick={createAccount}/>
        </>
    );
};
