import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { extractNumber } from '../../../utils';
import { FieldWithLabel } from '../field-with-label';

interface Props {
    value?: string;
    onChange: (val: string) => void;
    label: string;
    placeHolder: string;
    formatter: (val: string) => string;
    validator: (val: string) => boolean;
    disabled?: boolean;
    className?: string;
}

export const FieldMasked: FunctionComponent<Props> = props => {
    const [val, setVal] = useState('');

    const handleChange = useCallback((value: string) => {
        const newVal = props.formatter(extractNumber(value));
        const onlyNumbers = extractNumber(newVal);

        setVal(newVal);

        const validValue = props.validator(onlyNumbers) ? onlyNumbers : '';
        props.onChange(validValue);
    }, [setVal, props]);

    useEffect(() => {
        const newVal = props.formatter(extractNumber(props.value));

        if (val === newVal)
            return;

        setVal(newVal);
    }, [setVal, props, val]);

    return (
        <FieldWithLabel
            label={props.label}
            disabled={props.disabled}
            placeHolder={props.placeHolder}
            value={val}
            type='numeric'
            onChange={handleChange}
            className={props.className}
        />
    );
};
