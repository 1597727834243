import { getUrlGenerator } from '../utils';
import { CreateOrderDto } from '../types/Order';
import { Api } from './Api';
import { HasId } from '../types';

const baseUrl = process.env.REACT_APP_ORDER_API;

const url = getUrlGenerator(baseUrl);

export default class OrderService {
    public static createOrder = (order: CreateOrderDto): Promise<HasId> =>
        Api.post(url(), order);
}
