import { io as connect, Socket } from 'socket.io-client';
import { apiResponseFormatter, delay } from '../utils';
import { retrieveLastUpdatedDateOrDefault } from '../utils/storage';
import { PromiseCallback, VoidCallback } from '../types';

const baseUrl = process.env.REACT_APP_WEBSOCKET_API;

type Handler<T> = (obj: T) => any;

interface EventSubscription {
    event: string;
    handler: Handler<any>;
}

interface OnConnectionEmitterPayload {
    event: string;
    storageKey: string;
}

export class Websocket {
    private static _token: string | null;
    private static connection: Socket;

    private static path = '/api/websocket/socket/';
    private static suffix = '/socket/general';

    private static subscriptions: EventSubscription[] = [];
    private static onConnectionEmitters: OnConnectionEmitterPayload[] = [];
    private static onLoginEmitters: OnConnectionEmitterPayload[] = [];
    private static onLoginExecutors: VoidCallback[] = [];

    private static get token(): string {
        if (!this._token)
            return '';

        return `Bearer ${this._token}`;
    }

    public static onEvent = <T>(event: string, handler: Handler<T>) => {
        this.subscriptions = [...this.subscriptions, { event, handler }];
    };

    public static emitOnConnection = (event: string, storageKey: string) => {
        this.onConnectionEmitters = [...this.onConnectionEmitters, { event, storageKey }];

        if (!this.connection)
            return;

        this.connection.emit(event, retrieveLastUpdatedDateOrDefault(storageKey));
    };

    public static emitOnLogin = (event: string, storageKey: string) => {
        this.onLoginEmitters = [...this.onLoginEmitters, { event, storageKey }];

        if (!this.connection || !this._token)
            return;

        this.connection.emit(event, retrieveLastUpdatedDateOrDefault(storageKey));
    };

    public static executeOnLogin = (callback: PromiseCallback) => {
        this.onLoginExecutors = [...this.onLoginExecutors, callback];

        if (!this._token)
            return;

        callback();
    }

    public static updateToken = async (token: string) => {
        const lastTokenHadValue = !!this._token;
        this._token = token;

        if (!this.connection?.connected)
            return;

        const event = !!token ? 'update-token' : 'logoff';
        this.connection.emit(event, this.token);

        await delay(300);

        if (!lastTokenHadValue && !!token) {
            this.executeOnLoginEmitters();
        }

        if (lastTokenHadValue !== !!token) {
            this.executeOnConnectionEmitters();
        }
    };

    private static executeOnLoginEmitters = () => {
        this.onLoginEmitters.forEach(x => {
            this.connection.emit(x.event, retrieveLastUpdatedDateOrDefault(x.storageKey));
        });

        this.onLoginExecutors.forEach(fn => fn());
    };

    private static executeOnConnectionEmitters = () => {
        this.onConnectionEmitters.forEach(x => {
            this.connection.emit(x.event, retrieveLastUpdatedDateOrDefault(x.storageKey));
        });
    };

    public static close = (): void => {
        if (!this.connection?.connected)
            return;

        this.connection.close();
    };

    public static openWebsocketConnection = async (): Promise<void> =>
        new Promise(async (resolve, reject) => {
            try {
                if (this.connection?.connected) {
                    resolve();
                    return;
                }

                this.connection = connect(this.url, {
                    path: this.path,
                    reconnectionDelay: 10,
                    transports: ['websocket'],
                    query: {
                        authorization: this.token
                    },
                });

                this.connection.on('connect', () => {
                    console.log(`WS: Connection to ${this.url} established`);
                    resolve();

                    this.executeOnConnectionEmitters();

                    if (this._token)
                        this.executeOnLoginEmitters();

                    this.connection.onAny((event: string, value: any) => {
                        const handlers = this.subscriptions.filter(x => x.event === event);
                        const mappedObject = apiResponseFormatter(value);

                        handlers.forEach(x => x.handler(mappedObject));
                    });
                });

                this.connection.on('disconnect', () => {
                    console.log(`WS: Connection to websocket was closed`);
                });
            } catch (err) {
                console.error('Error on websocket');
                reject(err);
            }
        });

    private static get url(): string {
        return `${baseUrl}${this.suffix}`;
    }
}
