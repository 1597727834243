import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import styles from './edit-address.module.scss';
import { FieldWithLabel } from '../../utils/field-with-label';
import { Button } from '../../utils/button';
import { AddressWithLabel } from '../../../types/Address';

interface Props {
    address: AddressWithLabel;
    onSave: (obj: AddressWithLabel) => void;
}

export const EditAddress: FunctionComponent<Props> = props => {
    const [streetLine, setStreetLine] = useState('');
    const [neighborhood, setNeighborhood] = useState('');
    const [number, setNumber] = useState('');
    const [complement, setComplement] = useState<string>();

    useEffect(() => {
        if (!props.address)
            return;

        setStreetLine(props.address.streetLine);
        setNeighborhood(props.address.neighborhood);
        setNumber(props.address.number);
        setComplement(props.address.complement);
    }, [props.address]);

    const handleSaveClick = useCallback(() => {
        const newAddress: AddressWithLabel = {
            ...props.address,
            streetLine,
            neighborhood,
            number,
            complement
        };

        props.onSave(newAddress);
    }, [complement, neighborhood, number, props, streetLine]);

    const btnDisabled = !streetLine || !neighborhood || !number;

    return (
        <div className={styles.editAddress}>
            <div className={styles.form}>

                <FieldWithLabel
                    label="Endereço"
                    placeHolder="Avenida brasil"
                    onChange={setStreetLine}
                    disabled={!!props.address?.streetLine}
                    value={streetLine}
                    className={styles.streetLine} />

                <FieldWithLabel
                    label="Cidade"
                    placeHolder="Nome da cidade"
                    onChange={setNeighborhood}
                    disabled={!!props.address?.neighborhood}
                    value={neighborhood}
                    className={styles.neighborHood} />

                <FieldWithLabel
                    label="Numero"
                    placeHolder="2113"
                    onChange={setNumber}
                    value={number}
                    className={styles.number} />

                <FieldWithLabel
                    label="Complemento"
                    placeHolder="Apt 131"
                    onChange={setComplement}
                    value={complement}
                    className={styles.complement} />
            </div>

            <div className={styles.actions}>
                <Button label="Salvar" onClick={handleSaveClick} disabled={btnDisabled} />
            </div>
        </div>
    );
};
