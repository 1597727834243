import { useAppSelector } from './index';
import { getSettings } from '../store/settingsSlice';
import { formatNextChangeOnOpenState, nextChangeOnOpenState, storeIsOpen } from '../utils/isOpenedHelper';
import { useEffect, useState } from 'react';
import { VoidCallback } from '../types';

export const useStoreIsOpen = (callbackWhenChange?: VoidCallback, timeToVerify = 250): [boolean, string, number] => {
    const settings = useAppSelector(getSettings);
    const [willChangeOpenStateInMs, setWillChangeOpenStateInMs] = useState(0);

    useEffect(() => {
        if (!settings)
            return;

        const action = () => {
            const timeLeft = nextChangeOnOpenState(settings);
            setWillChangeOpenStateInMs(timeLeft);

            if (timeLeft < 250) {
                callbackWhenChange?.call(this);
            }
        };

        const timer = setInterval(action, timeToVerify);
        return () => clearTimeout(timer);
    }, [callbackWhenChange, settings, timeToVerify]);

    const isOpened = storeIsOpen(settings);
    const textWhenWillChange = formatNextChangeOnOpenState(settings);
    return [isOpened, textWhenWillChange, willChangeOpenStateInMs];
};
