import { AddressInfo } from './Address';

export enum PaymentMethodEnum {
    creditCard = 'creditCard',
    pix = 'pix',
    cash = 'cash'
}

export enum DeliveryMethodEnum {
    pickup = 'pickup',
    delivery = 'delivery'
}

export enum StatusEnum {
    pending = 'pending',
    inPreparation = 'inPreparation',
    readyToDeliver = 'readyToDeliver',
    inTransit = 'inTransit',
    delivered = 'delivered',
}

export enum StatusPaymentEnum {
    paid = 'paid',
    pending = 'pending',
}

export interface OrderProduct {
    quantity: number,
    product: {
        id: string;
        name: string;
        description: string;
        category: string;
        price: number;
        idImage: string;
        quantity?: string;
        annotation?: string;
        options?: string[];
        createdAt: Date;
        updatedAt: Date;
    },
    option?: string
    observation?: string;
}

interface BasicOrder {
    id: string;
    paymentMethod: PaymentMethodEnum;
    deliveryMethod: DeliveryMethodEnum;
    status: StatusEnum;
    statusPayment: StatusPaymentEnum;
    client: {
        id: string;
        name: string;
        email: string;
        phoneNumber: string;
        createdAt: Date;
        updatedAt: Date;
    },
    products: OrderProduct[],
    discountCoupon?: {
        id: string;
        key: string;
        reservationId: string;
        discountValue: number;
    },
    createdAt: Date;
    updatedAt: Date;
}

export interface ToGoOrder extends BasicOrder {
    deliveryMethod: DeliveryMethodEnum.delivery,
    address: AddressInfo;
    area: {
        id: string;
        name: string;
        deliveryFee: number;
    };
}

export interface DeliverOnCounterOrder extends BasicOrder {
    deliveryMethod: DeliveryMethodEnum.pickup;
}

type DeliverableOrder = ToGoOrder | DeliverOnCounterOrder

export interface PixPaymentOrder extends BasicOrder {
    paymentMethod: PaymentMethodEnum.pix;
    pixQrCode: string;
}

interface PaymentCardOrder extends BasicOrder {
    paymentMethod: PaymentMethodEnum.creditCard,
    paymentCard: {
        id: string;
        clientId: string;
        brand: string;
        name: string;
        document: string;
        address: {
            postalCode: string;
            number: string;
            complement?: string;
        };
        expiration: string;
        lastNumbers: string;
    };
}

interface PaymentOnDeliveryOrder extends BasicOrder {
    paymentMethod: PaymentMethodEnum.cash;
}

type PayableOrder = PixPaymentOrder | PaymentCardOrder | PaymentOnDeliveryOrder;

export type Order = DeliverableOrder & PayableOrder;


export interface CreateOrderDto {
    paymentMethod: PaymentMethodEnum;

    products: {
        quantity: number;
        productId: string;
        option?: string;
        observation?: string;
    }[];

    address?: AddressInfo;
    reservationDiscountCouponId?: string;
    paymentCardId?: string;
    deliveryMethod: DeliveryMethodEnum;
}
