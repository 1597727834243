import React from 'react';
import { ChooseDeliveryMethod } from './components/choose-address';
import { Cart } from './components/cart';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { StoreOpenedPopup } from './components/app/store-opened-popup';
import { Home } from './components/app/home';
import { Checkout } from './components/checkout';
import { SignInOrSignUp } from './components/sign-in-or-up';
import { useAppSelector } from './hooks';
import { getLoggedUser } from './store/authSlice';
import { Profile } from './components/profile';
import { AddCreditCard } from './components/add-credit-card';
import { IRoute } from './types/routes';
import { Order } from './components/order';
import { SnackBar } from './components/app/snack-bar';

const allRoutes: IRoute[] = [
    {
        path: '/',
        Component: Home,
    }
];

const loggedRoutes: IRoute[] = [
    {
        path: '/checkout',
        Component: Checkout
    },
    {
        path: '/profile',
        Component: Profile
    },
    {
        path: '/order',
        Component: Order
    },
    {
        path: '/order/:id',
        Component: Order
    }
];

function App() {
    const user = useAppSelector(getLoggedUser);

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    element={
                        <div className="App">
                            <Outlet />
                            <ChooseDeliveryMethod />
                            <Cart />
                            <StoreOpenedPopup />
                            <AddCreditCard />
                            <SnackBar />
                        </div>}>
                    {allRoutes.map(route => (
                        <Route path={route.path} key={route.path} element={<route.Component />} />
                    ))}
                    {loggedRoutes.map(route => (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={user ? <route.Component /> : <SignInOrSignUp />}
                        />
                    ))}
                    <Route path="*" element={<Navigate to={allRoutes[0].path} />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
