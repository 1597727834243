import styles from './tabs.module.scss';

interface Tab<T> {
    label: string;
    value: T;
}

interface Props<T> {
    tabs: Tab<T>[];
    activeTab: T;
    onChange: (tab: T) => void;
}

export const Tabs = <T extends string | number | undefined>({ tabs, activeTab, onChange }: Props<T>) => (
    <div className={styles.tabs}>
        {tabs.map(x => {
            const style = x.value === activeTab ? styles.active : '';
            return (
                <span key={x.value} className={style} onClick={() => onChange(x.value)}>
                    {x.label}
                </span>
            );
        })}
    </div>
);