import { FunctionComponent, useCallback } from 'react';
import { chooseDeliveryMethod, getChosenAddress, getChosenDeliveryMethod } from '../../../store/addressSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { MapsIcon } from '../../utils/icon/maps';
import styles from './delivery-checkout.module.scss';
import { DeliveryMethodEnum } from '../../../types/Order';
import { storeAddress } from '../../../utils';
import { formatAddress, formatSecondLineAddress } from '../../../utils/formatters';
import { TextButton } from '../../utils/text-button';
import { setChooseDeliveryPopupIsOpened } from '../../../store/appSlice';
import { Tabs } from '../../utils/tabs';

export const DeliveryCheckout: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const deliveryMethod = useAppSelector(getChosenDeliveryMethod);
    const chosenAddress = useAppSelector(getChosenAddress);

    const address = deliveryMethod === DeliveryMethodEnum.delivery ? chosenAddress! : storeAddress;

    const openChangeAddress = useCallback(() => {
        dispatch(setChooseDeliveryPopupIsOpened(true));
    }, [dispatch]);

    const changeDeliveryMethod = useCallback((method: DeliveryMethodEnum) => {
        if (method === DeliveryMethodEnum.delivery && !chosenAddress) {
            dispatch(setChooseDeliveryPopupIsOpened(true));
            return;
        }

        dispatch(chooseDeliveryMethod(method));
    }, [chosenAddress, dispatch]);

    const options = [
        { label: 'Entrega', value: DeliveryMethodEnum.delivery },
        { label: 'Retirada', value: DeliveryMethodEnum.pickup }
    ];

    if (!deliveryMethod)
        return null;

    return (
        <div className={styles.deliveryCheckout}>
            <Tabs tabs={options} activeTab={deliveryMethod} onChange={changeDeliveryMethod} />

            <div className={styles.deliveryInfo}>
                <MapsIcon type={deliveryMethod} />
                <div className={styles.texts}>
                    <span>{formatAddress(address)}</span>
                    <span>{formatSecondLineAddress(address)}</span>
                </div>
                {deliveryMethod === DeliveryMethodEnum.delivery && (
                    <TextButton label="Escolher" onClick={openChangeAddress} />
                )}
            </div>
        </div>
    );
};