import { ChangeEvent, FunctionComponent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import styles from './field-with-label.module.scss';

interface Props {
    prefix?: ReactNode;
    label: string;
    placeHolder: string;
    value?: string;
    onChange: (val: string) => void;
    disabled?: boolean;
    obscureText?: boolean;
    className?: string;
    type?: 'text' | 'numeric';
}

export const FieldWithLabel: FunctionComponent<Props> = props => {
    const inputElement = useRef<HTMLInputElement>(null);
    const [val, setVal] = useState('');

    useEffect(() => {
        if (!props.value || val === props.value) return;

        setVal(props.value);
    }, [props.value, val, setVal]);

    const handleChange = useCallback((el: ChangeEvent<HTMLInputElement>) => {
        const { value } = el.target;

        setVal(value);
        props.onChange(value);
    }, [props]);

    const styleHasValue = !!val ? styles.hasValue : '';
    const styleDisabled = props.disabled ? styles.disabled : '';

    return (
        <div className={`${styles.fieldWithLabel} ${styleHasValue} ${styleDisabled} ${props.className}`}>
            <label onClick={() => inputElement.current!.focus()}>
                {props.label}
            </label>
            {props.prefix}
            <input
                ref={inputElement}
                type={props.obscureText ? 'password' : ''}
                className={styles.input}
                placeholder={props.placeHolder}
                onChange={handleChange}
                disabled={props.disabled}
                value={val}
                inputMode={props.type || 'text'}
            />
        </div>
    );
};
