import { VoidCallback } from "../../../types";
import { FunctionComponent, ReactElement, useCallback } from "react";
import styles from './button.module.scss'
import { SpinLoading } from "../spin-loading";

interface Props {
    prefix?: ReactElement;
    className?: string;
    label: string;
    onClick: VoidCallback;
    disabled?: boolean;
    isLoading?: boolean;
    suffix?: ReactElement;
}


export const Button: FunctionComponent<Props> = props => {
    const handleClick = useCallback(() => {
        if (props.disabled || props.isLoading) return;

        props.onClick();
    }, [props]);

    let style = styles.button;
    if (props.className) style += ` ${props.className}`;
    if (props.disabled || props.isLoading) style += ` ${styles.disabled}`;

    return (
        <div className={style} onClick={handleClick}>
            {!!props.prefix && (props.prefix)}
            {!!props.isLoading && (
                <SpinLoading/>
            )}

            {!props.isLoading && <span>{props.label}</span>}

            {!!props.suffix && <span className={styles.suffix}>{(props.suffix)}</span>}
        </div>
    );
}