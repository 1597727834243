import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { delay } from '../utils';
import { AppThunk, AppThunkDispatcher, RootState } from './index';
import { removeOnLocalStorage, retrieveFromLocalStorage, saveOnLocalStorage } from '../utils/storage';
import { Client, UpdateClientDto } from '../types/Client';
import { Websocket } from '../services/Websocket';
import ClientService from '../services/ClientService';
import { NonFunctionPropertyNames } from '../types';

const CLIENT_KEY = 'client';

interface ClientState {
    mySelf: Client | null;
    isLoading: boolean;
}

const initialState: ClientState = {
    mySelf: null,
    isLoading: false
};

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        updateMySelf: (state, action: PayloadAction<Client | null>) => {
            state.mySelf = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        cleanState: (state) => initialState
    }
});

const { setIsLoading, updateMySelf, cleanState } = clientSlice.actions;

const getPatcherClient = <T extends NonFunctionPropertyNames<UpdateClientDto>, U extends string | boolean>(property: T) => (val: U): AppThunk<Promise<void>> => async (
    dispatch,
    getState
) => {
    const client = getState().client.mySelf!;

    dispatch(setIsLoading(true));

    const newClient: UpdateClientDto = {
        name: client.name,
        email: client.email,
        receiveEmails: client.receiveEmails
    };

    newClient[property] = val as any;

    return ClientService.updateMySelf(newClient)
        .then(() => {
            dispatch(setIsLoading(false));
        })
        .catch((err) => {
            dispatch(setIsLoading(false));
            throw err;
        });
};

export const patchName = getPatcherClient<'name', string>('name');
export const patchEmail = getPatcherClient<'email', string>('email');
export const patchReceiveEmails = getPatcherClient<'receiveEmails', boolean>('receiveEmails');

export const cleanClientStateAndStorage = (): AppThunk => async (dispatch) => {
    dispatch(cleanState());
    removeOnLocalStorage(CLIENT_KEY);
};

export const setupClientState = (): AppThunk => async (dispatch, getState) => {
    loadFromStorage(dispatch);

    Websocket.onEvent<Client>('update-client', client => {
        dispatch(updateMySelf(client));
        return updateOnStorage(getState);
    });
};

const updateOnStorage = async (getState: () => RootState): Promise<void> => {
    await delay(100);

    const lst = getState().client.mySelf;
    saveOnLocalStorage(CLIENT_KEY, lst);
};

const loadFromStorage = (dispatch: AppThunkDispatcher): void => {
    const mySelf = retrieveFromLocalStorage<Client>(CLIENT_KEY);

    if (!mySelf)
        return;

    dispatch(updateMySelf(mySelf));
};

export const getClientIsLoading = (root: RootState): boolean => root.client.isLoading;
export const getMySelf = (root: RootState): Client | null => root.client.mySelf;

export default clientSlice.reducer;
