import { FunctionComponent, ReactNode } from 'react';
import styles from './custom-address.module.scss';
import { VoidCallback } from '../../../types';
import { MyLocationIcon } from '../../utils/icon/my-location-icon';
import { Placeholder } from '../../utils/placeholder';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
    chooseDeliveryMethod,
    getAddressFromMyLocation,
    getChosenDeliveryMethod,
    getMyLocationAddress,
    getMyLocationAddressIsOutsideDeliveryZone,
    getMyLocationIsLoading,
    setMyLocationIsLoading
} from '../../../store/addressSlice';
import { formatFullAddress } from '../../../utils/formatters';
import { storeAddress } from '../../../utils';
import { StoreIcon } from '../../utils/icon/store';
import { setChooseDeliveryPopupIsOpened } from '../../../store/appSlice';
import { DeliveryMethodEnum } from '../../../types/Order';
import { SearchAddress } from "../search-address";
import { AddressOption } from "../address-option";

interface Props {
    requestToConfirm: VoidCallback;
}

export const ChooseCustomAddress: FunctionComponent<Props> = props => (
    <div className={styles.chooseCustomAddress}>
        <UseMyLocationOption {...props} />
        <UseStoreLocationOption/>
        <SearchAddress {...props} />
    </div>
);

const UseStoreLocationOption: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const isSelected = useAppSelector(getChosenDeliveryMethod) === DeliveryMethodEnum.pickup;

    return (
        <AddressOption
            Icon={StoreIcon}
            title="Retirar na loja"
            subtitle={formatFullAddress(storeAddress)}
            isSelected={isSelected}
            onClick={() => {
                dispatch(setChooseDeliveryPopupIsOpened(false));
                dispatch(chooseDeliveryMethod(DeliveryMethodEnum.pickup));
            }}
        />
    );

}

const UseMyLocationOption: FunctionComponent<Props> = props => {
    const dispatch = useAppDispatch();
    const myLocationAddress = useAppSelector(getMyLocationAddress);
    const myLocationIsOutsideDeliveryZone = useAppSelector(getMyLocationAddressIsOutsideDeliveryZone);

    const loading = useAppSelector(getMyLocationIsLoading);

    const subtitle = ((): string | ReactNode => {
        if (loading)
            return <Placeholder width={180} height={15}/>;

        if (!!myLocationAddress)
            return formatFullAddress(myLocationAddress);

        if (myLocationIsOutsideDeliveryZone)
            return 'Não atendemos na sua área';

        return 'Permitir acesso à localização';
    })();

    return (
        <AddressOption
            Icon={MyLocationIcon}
            title="Usar minha localização"
            subtitle={subtitle}
            isError={myLocationIsOutsideDeliveryZone}
            onClick={() => {
                dispatch(setMyLocationIsLoading(true));
                navigator.geolocation.getCurrentPosition(async (position) => {
                    const latLng: [number, number] = [position.coords.latitude, position.coords.longitude];

                    try {
                        await dispatch(getAddressFromMyLocation(latLng));
                        props.requestToConfirm();
                    } catch (err) {
                        console.error(err);
                    }
                });
            }}
        />
    );
};

