import { getUrlGenerator } from '../utils';
import { Api } from './Api';
import { LoginRequest } from '../types/LoginRequest';
import { ClientAddress, CreateAddressDto, CreateClientDto, UpdateClientDto } from '../types/Client';
import { CreatePaymentCardDto } from '../types/PaymentCard';
import { HasId } from '../types';
import { AddressWithLabel } from '../types/Address';

const baseUrl = process.env.REACT_APP_CLIENT_API;

const url = getUrlGenerator(baseUrl);

export default class ClientService {
    public static sendTwoFA = (phoneNumber: string): Promise<void> =>
        Api.post(url('auth', 'sendTwoFactorAuth'), ClientService.fixPhoneNumber({ phoneNumber }));

    public static login = ({ phoneNumber, twoFactorAuth }: LoginRequest): Promise<string> =>
        Api.post(url('auth', 'login'), ClientService.fixPhoneNumber({ phoneNumber, twoFactorAuth }));

    public static renewToken = (): Promise<void> =>
        Api.get(url('auth', 'renewToken'));

    public static createClient = (dto: CreateClientDto): Promise<void> =>
        Api.post(url(), ClientService.fixPhoneNumber(dto));

    public static createPaymentCard = (dto: CreatePaymentCardDto): Promise<HasId> =>
        Api.post(url('payment-card'), dto);

    public static deletePaymentCard = (id: string): Promise<void> =>
        Api.delete(url('payment-card', id));

    public static updateMySelf = (dto: UpdateClientDto): Promise<void> =>
        Api.put(url('my-self'), dto);

    public static createAddress = (dto: AddressWithLabel): Promise<void> => {
        const address: CreateAddressDto = {
            description: dto.description,
            isDefault: false,
            info: {
                streetLine: dto.streetLine,
                number: dto.number,
                postalCode: dto.postalCode,
                complement: dto.complement,
                city: dto.city,
                neighborhood: dto.neighborhood,
                state: dto.state,
                lat: dto.lat,
                lng: dto.lng
            }
        };
        return Api.post(url('address'), address);
    }

    public static deleteAddress = (id: string): Promise<void> =>
        Api.delete(url('address', id));

    private static fixPhoneNumber = <T extends { phoneNumber: string }>(obj: T): T => ({
        ...obj,
        phoneNumber: `55${obj.phoneNumber}`
    });
}
