import styles from './clean-app-bar.module.scss';
import Logo from '../../../assets/images/logo-colored.png';

export const CleanAppBar = () => {
    return (
        <div className={styles.cleanAppBar}>
            <img src={Logo} alt="Logo" />
        </div>
    );
};
