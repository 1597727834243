import { useAppSelector } from '../../../hooks';
import { FunctionComponent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Order } from '../../../types/Order';
import styles from './orders.module.scss';
import {getFilteredOrders} from '../../../store/orderSlice';
import { formatDeliveryInfo, formatPaymentInfo, formatStatusOrder, getTotalValue } from '../../../utils/formatters';

export const Orders: FunctionComponent = () => {
    const navigate = useNavigate();

    const orders = useAppSelector(getFilteredOrders);

    const openOrder = useCallback((data: Order) => () => {

        navigate(`/order/${data.id}`);
    }, [navigate]);


    if (!orders.length)
        return <div>Nenhum pedido encontrado</div>;

    return (
        <div className={styles.orders}>
            <table>
                <thead>
                <tr>
                    <th>Qtd Itens</th>
                    <th>Status</th>
                    <th>Pagamento</th>
                    <th>Entrega</th>
                    <th>Valor</th>
                    <th>Pedido</th>
                </tr>
                </thead>
                <tbody>
                {orders.map(x => (
                    <tr key={x.id} onClick={openOrder(x)}>
                        <td data-title="Qtd Itens">{x.products.reduce((agg, b) => b.quantity + agg, 0)}</td>
                        <td data-title="Status">{formatStatusOrder(x)}</td>
                        <td data-title="Pagamento">{formatPaymentInfo(x)}</td>
                        <td data-title="Entrega">{formatDeliveryInfo(x)}</td>
                        <td data-title="Valor">{getTotalValue(x)}</td>
                        <td data-title="Data">{x.createdAt.toLocaleString()}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};
