import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, AppThunkDispatcher, RootState } from './index';
import { removeOnLocalStorage, retrieveFromLocalStorage, saveOnLocalStorage } from '../utils/storage';
import { Websocket } from '../services/Websocket';
import { delay } from '../utils';
import { Settings } from '../types/Settings';

const SETTINGS_KEY = 'settings';

interface SettingsState {
    settingsData: Settings | null;
}

const initialState: SettingsState = {
    settingsData: null,
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setSettings: (state, action: PayloadAction<Settings | null>) => {
            state.settingsData = action.payload;
        },
    }
});

const { setSettings } = settingsSlice.actions;

export const cleanSettingsStateAndStorage = (): AppThunk => dispatch => {
    dispatch(setSettings(null));
    removeOnLocalStorage(SETTINGS_KEY);
};

export const setupSettingsState = (): AppThunk => dispatch => {
    loadFromStorage(dispatch);

    Websocket.onEvent<Settings>('update-settings', settings => {
        dispatch(setSettings(settings));
        return updateOnStorage(settings);
    });
};

const loadFromStorage = (dispatch: AppThunkDispatcher) => {
    const settings = retrieveFromLocalStorage<Settings>(SETTINGS_KEY);

    if (!settings) {
        return;
    }

    dispatch(setSettings(settings));
};

const updateOnStorage = async (settings: Settings) => {
    await delay(100);

    saveOnLocalStorage(SETTINGS_KEY, settings);
};

export const getSettings = (state: RootState): Settings | null => state.settings.settingsData;


export default settingsSlice.reducer;
