import { BasicUser, HasId } from '../types';
import { AddressInfo, AddressWithLabel } from '../types/Address';
import { LatLng } from 'leaflet';

export const extractNumber = (val: string | undefined): string =>
    val?.match(/\d/g)?.join('') || '';

export const getUrlGenerator = (baseUrl: string | undefined) => (...args: (string | number | object)[]) => {
    const params = args
        .map(x => {
            if (typeof x === 'object') {
                const urlParams = new URLSearchParams(x as Record<any, any>);

                const query = Array.from(urlParams.keys())
                    .filter(x => urlParams.get(x) !== 'undefined')
                    .map(x => `${x}=${urlParams.get(x)}`)
                    .join('&');

                return `?${query}`;
            }

            return x;
        })
        .join('/');

    return `${baseUrl}/${params}`;
};

export const isEmailValid = (email: string) => !!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.trim());

export const generateUuid = () =>
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });

export const delay = (time: number): Promise<void> =>
    new Promise<void>(resolve => setTimeout(resolve, time));


export const userFromToken = (token: string): BasicUser | undefined => {
    if (!token)
        return;

    const payloadToken = token.split('.')[1];

    if (!payloadToken)
        return;

    const decoded = window.atob(payloadToken);
    return JSON.parse(decoded);
};

export const isValidDateString = (value: any): boolean =>
    /^(\d{4})-(\d{2})-(\d{2})([T\s](\d{2}):(\d{2}):(\d{2})(\.(\d+)(Z)?)?)?$/.test(value);

export function apiResponseFormatter<T>(obj: T): T {
    if (!obj) return obj;

    if (Array.isArray(obj)) {
        return obj.map(i => apiResponseFormatter(i)) as any;
    }

    if (typeof obj === 'string' && isValidDateString(obj)) {
        return new Date(obj) as any;
    }

    if (typeof obj === 'object' && !(obj instanceof Date)) {
        return Object.keys(obj).reduce((acc, key) => {
            // @ts-ignore
            acc[key] = apiResponseFormatter(obj[key]);
            return acc;
        }, {}) as T;
    }

    return obj;
}

export const upsertOnList = <T extends HasId>(lst: T[], obj: T): T[] => upsertOnListCustom(lst, obj, x => x.id);

export const upsertOnListCustom = <T, U>(lst: T[], obj: T, getId: (obj: T) => U): T[] => {
    const position = lst.map(x => getId(x)).indexOf(getId(obj));

    if (position === -1)
        return [...lst, obj];

    const newList = lst.filter(x => getId(x) !== getId(obj));
    newList.splice(position, 0, obj);

    return newList;
};

export const removeFromList = <T extends HasId>(lst: T[], obj: T): T[] =>
    removeFromListCustom(lst, obj, x => x.id);

export const removeFromListCustom = <T, U>(lst: T[], obj: T, getId: (obj: T) => U): T[] =>
    lst.filter(x => getId(x) !== getId(obj));

export const getEnumKeys = <T extends string | number>(obj: Record<T, String>): T[] =>
    Object.keys(obj)
        .map(key => key as T);

export const storeAddress: AddressInfo = {
    streetLine: 'Av. das Araucárias',
    number: '1325',
    postalCode: '71935-250',
    city: 'Brasilia',
    neighborhood: 'Aguas Claras',
    state: 'DF',
    lat: '-15.839623663287739',
    lng: '-48.01923944754007'
};

export const getAddressId = (address: AddressWithLabel | null | LatLng): string => {
    if (!address)
        return '';

    return `${address.lat}${address.lng}`;
};

export const isSameAddress = (a: AddressWithLabel | null, b: AddressWithLabel | null): boolean => {
    if (!a || !b)
        return false;

    return getAddressId(a) === getAddressId(b);
};

export const isPhoneNumberValid = (phoneNumber: string): boolean => {
    if (!phoneNumber)
        return false

    return phoneNumber.length === 11;
}
