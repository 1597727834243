import { FunctionComponent } from 'react';
import styles from './app-bar.module.scss';
import logo from '../../../assets/images/logo.png';
import { AccountButton } from '../account-button';
import { useNavigate } from "react-router-dom";

export const AppBar: FunctionComponent = () => {

    const navigate = useNavigate();

    return (
        <div className={styles.appBar}>
            <div className={styles.content}>
                <div className={styles.logo} onClick={() => navigate('/')}>
                    <img src={logo} alt="logo"/>
                </div>
                <div className={styles.title}>
                    <AccountButton/>
                </div>
            </div>
        </div>
    );
};
