import { getUrlGenerator } from "../utils";
import { RequestReservationCoupon, ReservationCoupon } from "../types/DiscountCoupon";
import { Api } from "./Api";

const baseUrl = process.env.REACT_APP_DISCOUNT_COUPON_API;

const url = getUrlGenerator(baseUrl);

export class DiscountCouponService {
    public static reserveDiscountCoupon = (req: RequestReservationCoupon): Promise<ReservationCoupon> =>
        Api.post(url('reservation'), req)
}