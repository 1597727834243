const errorMessages = new Map();

errorMessages.set('invalid-user-password', 'Telefone ou senha está inválido');
errorMessages.set('invalid-two-factor-auth-code', 'Código inválido');
errorMessages.set('expired-two-factor-auth-code', 'Código expirou');
errorMessages.set('coupon-will-be-available', 'Cupom estará disponível em breve');

export const getErrorMessage = (errorCode: string | string[]): string => {
    const result = errorMessages.get(errorCode);

    if (!!result)
        return result;

    if (errorCode instanceof Array)
        return errorCode[0];

    return 'Erro desconhecido';
};
export const getMessageFromError = (error: Error): string => getErrorMessage(error?.message);
