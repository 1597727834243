import { With } from '../../../types';
import { OrderProduct as OrderProductDto } from '../../../types/Order';
import { FunctionComponent } from 'react';
import StorageService from '../../../services/StorageService';
import styles from './order-product.module.scss';
import { formatPriceAmount } from '../../../utils/formatters';

export const OrderProduct: FunctionComponent<With<OrderProductDto>> = ({ data }) => {
    return (
        <div className={styles.orderProduct}>
            <div className={styles.image}>
                <img src={StorageService.getUrl(data.product.idImage)} alt={data.product.name} />
            </div>
            <div>
                <h4>{data.quantity} x {data.product.name}</h4>
                {data.observation && (<p>{data.observation}</p>)}
                <span>{formatPriceAmount(data.quantity * data.product.price)}</span>
            </div>
        </div>
    );
};
