import { RootState } from "../store";
import { getSubTotalPrice } from "../store/cartSlice";
import { getChosenAddress, getChosenDeliveryMethod } from "../store/addressSlice";
import { getDiscountCouponReservation } from "../store/discountCouponSlice";
import { DeliveryMethodEnum } from "../types/Order";

export const getTotal = (root: RootState): number => {
    const subTotal = getSubTotalPrice(root);
    const chosenAddress = getChosenAddress(root);
    const deliveryMethod = getChosenDeliveryMethod(root);
    const discountCoupon = getDiscountCouponReservation(root);

    let total = subTotal;
    if (deliveryMethod === DeliveryMethodEnum.delivery && !!chosenAddress) {
        total += chosenAddress.shippingPrice;
    }
    if (!!discountCoupon) {
        total -= discountCoupon.discountValue;
    }
    return total;
}
