import { useAppDispatch, useAppSelector } from '../../hooks';
import { getAddCreditCardIsOpened, setAddCreditCardIsOpened } from '../../store/appSlice';
import { Dialog } from '../utils/dialog';
import { useCallback, useEffect, useState } from 'react';
import { getChosenAddress } from '../../store/addressSlice';
import Card, { CallbackArgument } from 'react-credit-cards-2';
import styles from './add-credit-card.module.scss';
import 'react-credit-cards-2/dist/lib/styles.scss';
import { Button } from '../utils/button';
import { CreatePaymentCardDto } from '../../types/PaymentCard';
import { addCreditCard, getPaymentIsLoading } from '../../store/paymentSlice';
import { useStepper } from '../../hooks/useStepper';
import { ArrowBackIcon } from '../utils/icon/arrow-back';
import { FieldFormatted } from '../utils/field-formatted';
import { expirationInputFormatter, numberCardInputFormatter, postalCodeInputFormatter } from '../../utils/formatters';
import { FieldWithLabel } from '../utils/field-with-label';
import { FieldMasked } from '../utils/field-masked';
import { FieldCpf } from '../utils/field-cpf';

export const AddCreditCard = () => {
    const dispatch = useAppDispatch();
    const isOpened = useAppSelector(getAddCreditCardIsOpened);
    const isLoading = useAppSelector(getPaymentIsLoading);

    const [step, increaseStep, decreaseStep] = useStepper(0);

    const chosenAddress = useAppSelector(getChosenAddress);

    const [name, setName] = useState('');
    const [document, setDocument] = useState('');
    const [number, setNumber] = useState('');
    const [ccv, setCcv] = useState('');
    const [expiration, setExpiration] = useState('');
    const [brand, setBrand] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [numberAddress, setNumberAddress] = useState('');
    const [complement, setComplement] = useState<string>();

    useEffect(() => {
        if (!chosenAddress)
            return;

        setPostalCode(chosenAddress.postalCode!);
        setNumberAddress(chosenAddress.number);
        setComplement(chosenAddress.complement);
    }, [chosenAddress]);

    const handleCallback = useCallback((type: CallbackArgument, isValid: boolean) => {
        if (!isValid)
            return;

        setBrand(type.issuer);
    }, []);

    const onClose = useCallback(() => {
        dispatch(setAddCreditCardIsOpened(false));
    }, [dispatch]);

    const handleSaveClick = useCallback(async () => {
        const dto: CreatePaymentCardDto = {
            number,
            ccv,
            expiration,
            brand,
            name,
            document,
            address: {
                postalCode,
                number: numberAddress,
                complement
            }
        };

        try {
            await dispatch(addCreditCard(dto));
            onClose();
        } catch (err) {

        }

    }, [brand, ccv, complement, dispatch, document, expiration, name, number, numberAddress, onClose, postalCode]);


    const btnDisabled = {
        '0': !number || !expiration || !ccv,
        '1': !name || name.split(' ').length < 2 || !document || !postalCode || !numberAddress
    }[step];

    return (
        <Dialog
            isOpen={isOpened}
            onClose={onClose}
            leading={step === 1 ? <ArrowBackIcon onClick={decreaseStep} className={styles.icon} /> : undefined}
            title={`Adicionar ${step === 0 ? '- Dados do Cartão' : 'Cartão'}`}
            className={styles.addCreditCard}>

            {step === 0 && (
                <>
                    <Card
                        number={number}
                        name={name}
                        expiry={expiration}
                        callback={handleCallback}
                        cvc={ccv} />
                    <div>
                        <FieldFormatted
                            className={styles.field}
                            label="Número do cartão"
                            placeHolder="5149 4509 9640 1784"
                            formatter={numberCardInputFormatter}
                            onChange={setNumber}
                            value={number} />

                        <FieldFormatted
                            className={styles.field}
                            onChange={setExpiration}
                            label="Validade"
                            placeHolder="05/27"
                            value={expiration}
                            formatter={expirationInputFormatter}
                        />

                        <FieldFormatted
                            className={styles.field}
                            label="CCV"
                            placeHolder="CCV"
                            onChange={setCcv}
                            formatter={val => val?.slice(0, 3)}
                            value={ccv} />

                    </div>
                </>
            )}

            {step === 1 && (
                <>
                    <div>
                        <h5>Dados Pessoais</h5>
                        <FieldWithLabel
                            className={styles.field}
                            label="Nome do Titular"
                            placeHolder="Marcos antonio carlos"
                            onChange={setName}
                            value={name} />
                        <FieldCpf value={document} onChange={setDocument} className={styles.field} />
                    </div>
                    <div>
                        <h5>Endereço</h5>
                        <FieldMasked
                            className={styles.field}
                            label="CEP"
                            placeHolder="CEP"
                            formatter={postalCodeInputFormatter}
                            validator={val => val.length === 8}
                            onChange={setPostalCode}
                            value={postalCode} />

                        <FieldWithLabel
                            className={styles.field}
                            label="Número"
                            placeHolder="Número"
                            onChange={setNumberAddress}
                            value={numberAddress} />

                        <FieldWithLabel
                            className={styles.field}
                            label="Complemento"
                            placeHolder="Complemento"
                            onChange={setComplement}
                            value={complement} />
                    </div>
                </>
            )}

            <div className={styles.actions}>
                {step === 0 && (<Button label="Continuar" onClick={increaseStep} disabled={btnDisabled} />)}
                {step === 1 && (
                    <Button label="Salvar" isLoading={isLoading} onClick={handleSaveClick} disabled={btnDisabled} />)}
            </div>
        </Dialog>
    );
};
