import { useCallback, useState } from 'react'
import { VoidCallback } from "../types";

export const useStepper = (initialStep = 0): [number, VoidCallback, VoidCallback, (val: number) => void] => {
    const [step, setStep] = useState(initialStep)

    const increaseStep = useCallback(() => setStep(step + 1), [step, setStep])
    const decreaseStep = useCallback(() => setStep(step - 1), [step, setStep])

    return [step, increaseStep, decreaseStep, setStep]
}
