import { FunctionComponent, useCallback } from 'react';
import styles from './products-cart.module.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getProductsInCart, selectCartProduct, upsertCartProduct } from '../../../store/cartSlice';
import { With } from '../../../types';
import { OrderProduct } from '../../../types/Order';
import StorageService from '../../../services/StorageService';
import { formatPriceAmount } from '../../../utils/formatters';
import { TrashIcon } from '../../utils/icon/trash';
import { EditIcon } from '../../utils/icon/edit';

export const ProductsCart: FunctionComponent = () => {
    const products = useAppSelector(getProductsInCart);

    return (
        <div className={styles.productsCart}>
            {products.map(x => <ProductCart key={x.product.id} data={x} />)}
        </div>
    );
};

const ProductCart: FunctionComponent<With<OrderProduct>> = ({ data }) => {
    const dispatch = useAppDispatch();
    const totalPrice = data.quantity * data.product.price;

    const increaseQuantity = useCallback(() => {
        dispatch(upsertCartProduct({ ...data, quantity: data.quantity + 1 }));
    }, [data, dispatch]);

    const decreaseQuantity = useCallback(() => {
        dispatch(upsertCartProduct({ ...data, quantity: data.quantity - 1 }));
    }, [data, dispatch]);

    const selectProduct = useCallback(() => {
        dispatch(selectCartProduct(data));
    }, [data, dispatch]);

    return (
        <div className={styles.product}>
            <div className={styles.content}>
                <img src={StorageService.getUrl(data.product.idImage)} alt={data.product.name} />
                <div className={styles.info}>
                    <h4>{data.product.name}</h4>
                    {!!data.option && (<h6>{data.option}</h6>)}
                    {!!data.observation && (<span>{data.observation}</span>)}
                </div>
                <EditIcon className={styles.editIcon} onClick={selectProduct} />
            </div>
            <div className={styles.actions}>
                <span>{formatPriceAmount(totalPrice)}</span>

                <div className={styles.quantityController}>
                    <span onClick={decreaseQuantity}>
                        {data.quantity === 1 && (
                            <TrashIcon className={styles.trashIcon} />
                        )}
                        {data.quantity > 1 && ('-')}
                    </span>
                    <span>{data.quantity}</span>
                    <span onClick={increaseQuantity}>+</span>
                </div>
            </div>
        </div>
    );
};
