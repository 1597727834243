import { FunctionComponent } from 'react';
import styles from './spinLoading.module.scss';

export const SpinLoading: FunctionComponent = () => {
    return (
        <div className={styles.spinLoading}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};
