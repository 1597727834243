import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { extractNumber } from '../../../utils';
import { FieldWithLabel } from '../field-with-label';

interface Props {
    value?: string;
    onChange: (val: string) => void;
    label: string;
    placeHolder: string;
    formatter: (val: string) => string;
    disabled?: boolean;
    className?: string;
}

export const FieldFormatted: FunctionComponent<Props> = props => {
    const [val, setVal] = useState('');

    const handleChange = useCallback((value: string) => {
        const onlyNumbers = extractNumber(value);

        const newVal = props.formatter(onlyNumbers);
        setVal(newVal);

        props.onChange(onlyNumbers);
    }, [setVal, props]);

    useEffect(() => {
        const newVal = props.formatter(extractNumber(props.value));

        if (val === newVal)
            return;

        setVal(newVal);
    }, [setVal, props, val]);

    return (
        <FieldWithLabel
            label={props.label}
            disabled={props.disabled}
            placeHolder={props.placeHolder}
            value={val}
            onChange={handleChange}
            className={props.className}
        />
    );
};
