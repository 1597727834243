import { PaymentMethodEnum } from '../types/Order';
import { Client, ClientPaymentCard } from '../types/Client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from './index';
import { CreatePaymentCardDto } from '../types/PaymentCard';
import ClientService from '../services/ClientService';
import { HasId } from '../types';
import { Websocket } from '../services/Websocket';

interface PaymentState {
    paymentCards: ClientPaymentCard[];
    isLoading: boolean;
    selectedCardId: string | null;
    chosenPaymentMethod: PaymentMethodEnum | null;
}

const initialState: PaymentState = {
    paymentCards: [],
    isLoading: false,
    selectedCardId: null,
    chosenPaymentMethod: null
};

const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        setPaymentCards: (state, action: PayloadAction<ClientPaymentCard[]>) => {
            state.paymentCards = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setSelectedCardId: (state, action: PayloadAction<string | null>) => {
            state.selectedCardId = action.payload;
        },
        setChosenPaymentMethod: (state, action: PayloadAction<PaymentMethodEnum>) => {
            state.chosenPaymentMethod = action.payload;
        }
    }
});

export const { setPaymentCards, setIsLoading, setSelectedCardId, setChosenPaymentMethod } = paymentSlice.actions;

export const addCreditCard = (dto: CreatePaymentCardDto): AppThunk<Promise<void>> => async (dispatch, getState) => {
    dispatch(setIsLoading(true));

    return ClientService.createPaymentCard(dto)
        .then((obj: HasId) => {
            dispatch(setIsLoading(false));
            dispatch(setSelectedCardId(obj.id));
        })
        .catch(err => {
            dispatch(setIsLoading(false));
            throw err;
        });
};

export const deleteCard = (cardId: string): AppThunk<Promise<void>> => async (dispatch, getState) => {
    dispatch(setIsLoading(true));

    return ClientService.deletePaymentCard(cardId)
        .then(() => {
            dispatch(setIsLoading(false));
            dispatch(setSelectedCardId(null));
        })
        .catch(err => {
            dispatch(setIsLoading(false));
            throw err;
        });

};

export const chooseNonCardPaymentMethod = (method: PaymentMethodEnum): AppThunk => dispatch => {
    dispatch(setChosenPaymentMethod(method));
    dispatch(setSelectedCardId(null));
};

export const choosePaymentCard = (cardId: string): AppThunk => dispatch => {
    dispatch(setSelectedCardId(cardId));
    dispatch(setChosenPaymentMethod(PaymentMethodEnum.creditCard));
};

export const setupPaymentState = (): AppThunk => async dispatch => {
    Websocket.onEvent<Client>('update-client', client => {
        dispatch(setPaymentCards(client.paymentCards));
    });
};


export const getPaymentCards = (root: RootState): ClientPaymentCard[] => root.payment.paymentCards;
export const getChosenPaymentMethod = (root: RootState): PaymentMethodEnum | null => root.payment.chosenPaymentMethod;
export const getSelectedCardId = (root: RootState): string | null => root.payment.selectedCardId;
export const getPaymentIsLoading = (root: RootState): boolean => root.payment.isLoading;

export default paymentSlice.reducer;
