import styles from './edit-personal-data.module.scss';
import { Label } from '../../utils/label';
import { FieldWithLabel } from '../../utils/field-with-label';
import { SpinLoading } from '../../utils/spin-loading';
import { CloseIcon } from '../../utils/icon/close';
import { CheckIcon } from '../../utils/icon/check';
import { phoneFormatter } from '../../../utils/formatters';
import { Switch } from '../../utils/switch';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getClientIsLoading, getMySelf, patchEmail, patchName, patchReceiveEmails } from '../../../store/clientSlice';
import { useCallback, useEffect, useState } from 'react';
import { isEmailValid } from '../../../utils';

export const EditPersonalData = () => {
    const client = useAppSelector(getMySelf);
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(getClientIsLoading);

    const [isEditingName, setIsEditingName] = useState<boolean>(false);
    const [isEditingEmail, setIsEditingEmail] = useState<boolean>(false);

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const resetFields = useCallback(() => {
        if (!client)
            return;

        setName(client.name);
        setEmail(client.email);
        setIsEditingName(false);
        setIsEditingEmail(false);
    }, [client]);

    useEffect(resetFields, [resetFields]);

    const updateName = useCallback(async () => {
        if (!client)
            return;

        try {
            await dispatch(patchName(name));
            setIsEditingName(false);
        } catch (err) {
        }
    }, [client, name, dispatch]);

    const updateEmail = useCallback(async () => {
        if (!client)
            return;

        try {
            await dispatch(patchEmail(email));
            setIsEditingEmail(false);
        } catch (err) {
        }
    }, [client, email, dispatch]);

    const updateReceiveEmails = useCallback(async () => {
        if (!client)
            return;

        try {
            await dispatch(patchReceiveEmails(!client.receiveEmails));
        } catch (err) {
        }
    }, [client, dispatch]);

    const storeEmail = useCallback((newVal: string) => {
        setEmail(isEmailValid(newVal) ? newVal : '');
    }, []);

    if (!client)
        return null;

    return (
        <div className={styles.editPersonalData}>
            {!isEditingName && (
                <Label label="Name" value={name} onEdit={() => setIsEditingName(true)} />
            )}
            {isEditingName && (
                <div>
                    <FieldWithLabel
                        label="Nome"
                        placeHolder="Nome completo"
                        value={name}
                        onChange={setName}
                        className={styles.field} />
                    {isLoading && (<SpinLoading />)}
                    {!isLoading && (
                        <>
                            <CloseIcon onClick={resetFields} className={styles.closeIcon} />
                            <CheckIcon onClick={updateName} className={styles.checkIcon} />
                        </>
                    )}
                </div>
            )}

            {!isEditingEmail && (
                <Label label="Email" value={email} onEdit={() => setIsEditingEmail(true)} />
            )}
            {isEditingEmail && (
                <div>
                    <FieldWithLabel
                        label="Email"
                        placeHolder="marcos@gmail.com"
                        value={email}
                        onChange={storeEmail}
                        className={styles.field} />
                    {isLoading && (<SpinLoading />)}
                    {!isLoading && (
                        <>
                            <CloseIcon onClick={resetFields} className={styles.closeIcon} />
                            {!!email && (
                                <CheckIcon onClick={updateEmail} className={styles.checkIcon} />
                            )}
                        </>
                    )}
                </div>
            )}

            <Label label="Telefone" value={phoneFormatter(client.phoneNumber)} />

            <div>
                <span>Receber emails</span>
                {isLoading && (<SpinLoading />)}
                {!isLoading && (<Switch onChange={updateReceiveEmails} value={client.receiveEmails} />)}
            </div>
        </div>
    );
};
