import { Product } from '../types/Product';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, AppThunkDispatcher, RootState } from './index';
import { OrderProduct } from '../types/Order';
import { delay, upsertOnListCustom } from '../utils';
import { removeOnLocalStorage, retrieveFromLocalStorage, saveOnLocalStorage } from '../utils/storage';
import { Websocket } from '../services/Websocket';
import { GetStateFn } from '../types';

const CART_KEY = 'cart';

interface CartSlice {
    products: OrderProduct[];
    selectedProduct: OrderProduct | null;
}

const initialState: CartSlice = {
    products: [],
    selectedProduct: null
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        upsertCartProduct: (state, action: PayloadAction<OrderProduct>) => {
            const isZero = action.payload.quantity === 0;
            const lst = isZero
                ? state.products.filter(p => p.product.id !== action.payload.product.id)
                : upsertOnListCustom(state.products, action.payload, p => p.product.id);
            state.products = lst;
        },
        removeProduct: (state, action: PayloadAction<string>) => {
            state.products = state.products.filter(p => p.product.id !== action.payload);
        },
        updateProducts: (state, action: PayloadAction<OrderProduct[]>) => {
            state.products = action.payload;
        },
        selectCartProduct: (state, action: PayloadAction<OrderProduct | null>) => {
            state.selectedProduct = action.payload;
        },
        clearCart: (state) => {
            state.products = [];
        }
    }
});

export const { clearCart, upsertCartProduct, selectCartProduct } = cartSlice.actions;
const { removeProduct } = cartSlice.actions;

export const cleanShoppingCart = (): AppThunk => dispatch => {
    dispatch(clearCart());
};

export const unselectCartProduct = (): AppThunk => dispatch => {
    dispatch(selectCartProduct(null));
};

export const addProduct = (product: Product): AppThunk => (dispatch, getState) => {
    const newProduct: OrderProduct = {
        product: product,
        quantity: 1
    };

    const existentProduct = getState().cart.products.find(p => p.product.id === product.id);

    const orderProduct = !!existentProduct ? {
        ...existentProduct,
        quantity: existentProduct.quantity + 1
    } : newProduct;

    if (!existentProduct && !!product.options?.length) {
        dispatch(selectCartProduct(orderProduct));
        return;
    }

    dispatch(upsertCartProduct(orderProduct));
    return updateOnStorage(getState);
};

export const getSubTotalPrice = (root: RootState): number =>
    root.cart.products.reduce((acc, p) => acc + p.product.price * p.quantity, 0);
export const getProductsInCart = (root: RootState): OrderProduct[] => root.cart.products;
export const getSelectedProduct = (root: RootState): OrderProduct | null => root.cart.selectedProduct;

const updateOnStorage = async (getState: GetStateFn) => {
    await delay(100);

    const products = getState().cart.products;
    saveOnLocalStorage(CART_KEY, products);
};
export const cleanCartStateAndStorage = (): AppThunk => dispatch => {
    dispatch(clearCart());
    removeOnLocalStorage(CART_KEY);
};

export const setupCartState = (): AppThunk => dispatch => {
    loadFromStorage(dispatch);

    Websocket.onEvent<Product>('update-product', product => {
        if (product.isEnabled)
            return;

        dispatch(removeProduct(product.id));
    });
};

export const loadFromStorage = (dispatch: AppThunkDispatcher) => {
    const cart = retrieveFromLocalStorage<OrderProduct[]>(CART_KEY);

    if (!cart) {
        return;
    }

    dispatch(cartSlice.actions.updateProducts(cart));
};

export default cartSlice.reducer;