import { FunctionComponent, ReactNode, useCallback } from "react";
import { VoidCallback } from "../../../types";
import styles from './dialog.module.scss';
import { CloseIcon } from "../icon/close";
import useKeyPress from "../../../hooks/useKeyPress";

interface Props {
    leading?: ReactNode;
    isOpen: boolean;
    onClose?: VoidCallback;
    title?: string;
    children: ReactNode;
    className?: string;
}

export const Dialog: FunctionComponent<Props> = props => {
    const handleClose = useCallback(() => {
        props.onClose?.call(this)
    }, [props.onClose])

    useKeyPress('Escape', handleClose, []);

    if (!props.isOpen)
        return null;

    return (
        <div className={styles.dialog}>
            <div className={styles.content}>
                <div className={styles.header}>
                    {!!props.title && (
                        <div className={styles.title}>
                            {!!props.leading && (<div className={styles.leading}>{props.leading}</div>)}
                            <h3>{props.title}</h3>
                        </div>
                    )}
                    {!!props.onClose && <CloseIcon className={styles.closeIcon} onClick={handleClose}/>}
                </div>

                <div className={props.className}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}