import styles from './checkout.module.scss';
import { DeliveryCheckout } from './delivery-checkout';
import { useAppSelector } from '../../hooks';
import { getSettings } from '../../store/settingsSlice';
import { PaymentInfo } from './payment-info';
import { CartSummary } from './cart-summary';
import { CreateOrderButton } from './create-order-button';
import { CleanAppBar } from '../app/clean-app-bar';
import {SelectedOrderProduct} from "../selected-order-product";
import React from "react";

export const Checkout = () => {
    const settings = useAppSelector(getSettings);

    if (!settings)
        return null;

    return (
        <div className={styles.checkout}>
            <CleanAppBar />
            <div className={styles.content}>
                <h1>Finalize seu pedido</h1>
                <div>
                    <DeliveryCheckout />
                    <h4>Tempo estimado de {settings.estimatedTimeToDeliverInMinutes} min</h4>
                    <PaymentInfo />
                    <CreateOrderButton />
                </div>
                <div>
                    <CartSummary />
                </div>

            </div>
            <SelectedOrderProduct/>
        </div>
    );
};
