import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { delay, upsertOnList } from '../utils';
import { AppThunk, AppThunkDispatcher, RootState } from './index';
import { CategoryEnum, Product } from '../types/Product';
import { Websocket } from '../services/Websocket';
import {
    removeOnLocalStorage,
    retrieveFromLocalStorage,
    saveLastUpdatedDate,
    saveOnLocalStorage
} from '../utils/storage';


const PRODUCTS_KEY = 'products';
const PRODUCTS_LAST_UPDATED_DATE = 'products_last_updated';

interface ProductState {
    lstProducts: Product[];
    filteredCategories: CategoryEnum[];
}

const initialState: ProductState = {
    lstProducts: [],
    filteredCategories: [],
};

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        upsertProduct: (state, action: PayloadAction<Product>) => {
            state.lstProducts = upsertOnList(state.lstProducts, action.payload);
        },
        removeProduct: (state, action: PayloadAction<string>) => {
            state.lstProducts = state.lstProducts.filter(x => x.id !== action.payload);
        },
        updateProductList: (state, action: PayloadAction<Product[]>) => {
            state.lstProducts = action.payload;
        },
        setFilteredCategories: (state, action: PayloadAction<CategoryEnum[]>) => {
            state.filteredCategories = action.payload;
        }
    }
});

const {
    upsertProduct,
    removeProduct,
    updateProductList,
} = productSlice.actions;

export const { setFilteredCategories } = productSlice.actions;

export const cleanProductsStateAndStorage = (): AppThunk => (dispatch, getState) => {
    dispatch(updateProductList([]));
    removeOnLocalStorage(PRODUCTS_KEY);
    removeOnLocalStorage(PRODUCTS_LAST_UPDATED_DATE);
};

export const setupProductState = (): AppThunk => (dispatch, getState) => {
    loadFromStorage(dispatch);

    Websocket.onEvent<Product>('update-product', product => {
        dispatch(upsertProduct(product));
        return updateOnStorage(getState);
    });

    Websocket.onEvent<{ id: string }>('delete-product', ({ id }) => {
        dispatch(removeProduct(id));
        return updateOnStorage(getState);
    });

    Websocket.emitOnConnection('subscribe-products', PRODUCTS_LAST_UPDATED_DATE);
};

const updateOnStorage = async (getState: () => RootState): Promise<void> => {
    await delay(100);

    const products = getState().product.lstProducts;
    saveOnLocalStorage(PRODUCTS_KEY, products);
    saveLastUpdatedDate(PRODUCTS_LAST_UPDATED_DATE, products);
};

const loadFromStorage = (dispatch: AppThunkDispatcher): void => {
    const lst = retrieveFromLocalStorage<Product[]>(PRODUCTS_KEY);

    if (!lst)
        return;

    lst.forEach(product => dispatch(upsertProduct(product)));
};

export const getProducts = (state: RootState): Product[] => state.product.lstProducts;
export const getProduct = (id: string | undefined) => (state: RootState): Product | undefined =>
    state.product.lstProducts.find(x => x.id === id);

export const getFilersCategories = (state: RootState): CategoryEnum[] => state.product.filteredCategories;
export default productSlice.reducer;
