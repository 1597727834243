import { FunctionComponent } from 'react';
import { IconProps } from '../../../types';

export const ArrowBackIcon: FunctionComponent<IconProps> = props => {
    return (
        <svg
            onClick={props.onClick}
            className={!!props.onClick ? `${props.className} clickable-icon` : props.className}
            xmlns="http://www.w3.org/2000/svg"
            height={props.height || 24}
            viewBox="0 -960 960 960"
            width={props.width || 24}>
            <path d="M655-80 255-480l400-400 56 57-343 343 343 343-56 57Z" />
        </svg>
    );
};
