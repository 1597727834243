import {AppBar} from '../app-bar';
import {AddressBar} from '../address-bar';
import {Products} from '../../products';
import React from 'react';
import {Whatsapp} from '../whatsapp';
import styles from './home.module.scss';
import {SelectedOrderProduct} from "../../selected-order-product";
import {setCartIsOpened} from "../../../store/appSlice";
import {useAppDispatch} from "../../../hooks";

export const Home = () => {
    const dispatch = useAppDispatch();
    return (
        <div className={styles.home}>
            <AppBar/>
            <AddressBar/>
            <Products/>
            <Whatsapp/>
            <SelectedOrderProduct runAfterUpdate={() => {
                dispatch(setCartIsOpened(true));
            }}/>
        </div>
    );
};
