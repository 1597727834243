import { ChangeEvent, FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import styles from './field.module.scss';

interface Props {
    prefix?: ReactNode;
    suffix?: ReactNode;
    placeholder: string;
    value?: string;
    onChange: (val: string) => void;
    className?: string;
}

export const Field: FunctionComponent<Props> = props => {
    const [val, setVal] = useState('');

    useEffect(() => {
        if (!props.value || val === props.value) return;

        setVal(props.value);
    }, [props.value, val, setVal]);

    const handleChange = useCallback((el: ChangeEvent<HTMLInputElement>) => {
        const { value } = el.target;

        setVal(value);
        props.onChange(value);
    }, [props]);

    return (
        <div className={`${styles.field} ${props.className}`}>
            {!!props.prefix && (
                <div className={styles.prefix}>{props.prefix}</div>
            )}
            <input
                placeholder={props.placeholder}
                onChange={handleChange}
                value={val}
            />
            {!!props.suffix && (
                <div className={styles.suffix}>{props.suffix}</div>
            )}
        </div>
    );
};
