import { useAppSelector } from "./index";
import { getSettings } from "../store/settingsSlice";
import { getSubTotalPrice } from "../store/cartSlice";

export const useMinimumPurchase = (): [boolean, number] => {
    const minValue = useAppSelector(getSettings)?.minimumPurchaseValue;

    const totalValue = useAppSelector(getSubTotalPrice);
    const isValid = minValue !== undefined && totalValue >= minValue;

    return [isValid, minValue || 0];
}