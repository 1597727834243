import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from './index';
import { Alert, typeAlert } from '../types';
import { generateUuid } from '../utils';

interface NotificationState {
    snack: string | null;
    alerts: Alert[];
}

const initialState: NotificationState = {
    snack: null,
    alerts: []
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setSnack: (state, action: PayloadAction<string>) => {
            state.snack = action.payload;
        },
        cleanSnack: state => {
            state.snack = null;
        },
        addAlert: (state, action: PayloadAction<Alert>) => {
            state.alerts = [...state.alerts, action.payload];
        },
        hideAlert: (state, action: PayloadAction<string>) => {
            state.alerts = state.alerts.map(x => {
                if (x.id !== action.payload)
                    return x;

                x.show = false;
                return x;
            });
        },
        removeAlert: (state, action: PayloadAction<string>) => {
            state.alerts = state.alerts.filter(x => x.id !== action.payload);
        }
    }
});

const { setSnack, cleanSnack, addAlert, removeAlert, hideAlert } = notificationSlice.actions;

export const showSnack = (text: string): AppThunk => dispatch => {
    dispatch(setSnack(text));

    setTimeout(() => dispatch(cleanSnack()), 10000);
};

export const closeAlert = (alert: Alert): AppThunk => dispatch => {
    dispatch(hideAlert(alert.id));

    setTimeout(() => dispatch(removeAlert(alert.id)), 30000);
};

export const showAlert = (text: string, type: typeAlert = 'success'): AppThunk => dispatch => {
    const alert = { id: generateUuid(), text, type, show: true };
    dispatch(addAlert(alert));

    setTimeout(() => dispatch(closeAlert(alert)), 4000);
};


export const getSnack = (state: RootState): string | null => state.notification.snack;
export const getAlerts = (state: RootState): Alert[] => state.notification.alerts;


export default notificationSlice.reducer;
