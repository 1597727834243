import styles from './address-bar.module.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { FunctionComponent, useCallback } from 'react';
import { DeliveryMethodEnum } from '../../../types/Order';
import { StoreIcon } from '../../utils/icon/store';
import { storeAddress } from '../../../utils';
import { formatFullAddress, formatPriceAmount } from '../../../utils/formatters';
import { Placeholder } from '../../utils/placeholder';
import { HomeIcon } from '../../utils/icon/home';
import { ShoppingBagIcon } from '../../utils/icon/shopping-bag';
import { setCartIsOpened, setChooseDeliveryPopupIsOpened } from '../../../store/appSlice';
import {
    chooseDeliveryMethod,
    getChosenAddress,
    getChosenDeliveryMethod
} from '../../../store/addressSlice';
import { AddressInfo } from "../../../types/Address";
import { IconProps } from "../../../types";
import { getSubTotalPrice } from "../../../store/cartSlice";

export const AddressBar = () => {
    return (
        <div className={styles.addressBar}>
            <div className={styles.content}>
                <ChosenAddress/>
                <div className={styles.actions}>
                    <ChooseDeliveryMethod/>
                    <TotalInCard/>
                </div>
            </div>
        </div>
    );
};

const allDeliveryMethods: Record<DeliveryMethodEnum, string> = {
    pickup: 'Retirada',
    delivery: 'Entrega'
};

export const ChooseDeliveryMethod = () => {
    const dispatch = useAppDispatch();
    const deliveryMethod = useAppSelector(getChosenDeliveryMethod);

    const handleClick = useCallback((method: DeliveryMethodEnum) => () => {
        dispatch(chooseDeliveryMethod(method));
    }, [dispatch]);

    const isActiveStyle = useCallback((method: DeliveryMethodEnum) => {
        if (method === deliveryMethod)
            return styles.active;
        return '';
    }, [deliveryMethod]);

    return (
        <div className={styles.chooseDeliveryMethod}>
            {Object.keys(allDeliveryMethods).map(x => {
                const option = x as DeliveryMethodEnum;
                return (
                    <span
                        key={x}
                        onClick={handleClick(option)}
                        className={isActiveStyle(option)}>
                        {allDeliveryMethods[option]}
                    </span>
                );
            })}
        </div>
    );
};

export const TotalInCard = () => {
    const dispatch = useAppDispatch();
    const total = useAppSelector(getSubTotalPrice);

    const openCart = () => dispatch(setCartIsOpened(true));
    return (
        <div className={styles.totalInCard} onClick={openCart}>
            <ShoppingBagIcon className={styles.icon} height={36} width={36}/>
            <span>{formatPriceAmount(total)}</span>
        </div>
    );
};

interface ChosenAddressTemplateProps {
    Icon: FunctionComponent<IconProps>;
    label: string;
    address: AddressInfo;
}

export const ChosenAddress = () => {
    const dispatch = useAppDispatch();
    const deliveryMethod = useAppSelector(getChosenDeliveryMethod);
    const address = useAppSelector(getChosenAddress);

    const open = useCallback(() => {
        dispatch(setChooseDeliveryPopupIsOpened(true));
    }, [dispatch]);


    const ChosenAddressTemplate: FunctionComponent<ChosenAddressTemplateProps> = props => (
        <div className={styles.chosenAddress} onClick={open}>
            <props.Icon className={styles.icon}/>
            <span>{props.label}: </span>
            <span>{formatFullAddress(props.address)}</span>
        </div>
    )

    if (deliveryMethod === DeliveryMethodEnum.pickup)
        return <ChosenAddressTemplate Icon={StoreIcon} label="Loja" address={storeAddress}/>;

    if (!address)
        return (
            <div className={styles.chosenAddress}>
                <Placeholder width={360} height={40}/>
            </div>
        );

    return <ChosenAddressTemplate Icon={HomeIcon} label="Endereço" address={address}/>
};


