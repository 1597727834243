import { FunctionComponent, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getProducts } from '../../store/productSlice';
import { CategoryEnum, Product as ProductDto } from '../../types/Product';
import { With } from '../../types';
import styles from './products.module.scss';
import { formatCategory, formatPriceAmount } from '../../utils/formatters';
import StorageService from '../../services/StorageService';
import { Button } from '../utils/button';
import { addProduct } from '../../store/cartSlice';
import { ShoppingBagIcon } from "../utils/icon/shopping-bag";


const allCategories: CategoryEnum[] = [
    CategoryEnum.entries,
    CategoryEnum.sideDishes,
    CategoryEnum.smokedMeets,
    CategoryEnum.grilled,
    CategoryEnum.burgers,
    CategoryEnum.sandwiches,
    CategoryEnum.beverages,
    CategoryEnum.sauces,
    CategoryEnum.salads,
    CategoryEnum.deserts,
];

export const Products: FunctionComponent = () => {
    return (
        <div className={styles.products}>
            {allCategories.map(c => <Category key={c} data={c}/>)}
        </div>
    );
};

const Category: FunctionComponent<With<CategoryEnum>> = ({ data }) => {
    const products = useAppSelector(getProducts)
        .filter(p => p.category === data)
        .filter(x => x.isEnabled);

    if (!products.length)
        return null;

    const sortedProducts = products.sort((a, b) => a.sortIndex - b.sortIndex);
    return (
        <div className={styles.category}>
            <h3>{formatCategory(data)}</h3>
            <div className={styles.productList}>
                {sortedProducts.map(p => <Product key={p.id} data={p}/>)}
            </div>
        </div>
    );
};

const Product: FunctionComponent<With<ProductDto>> = ({ data }) => {
    const dispatch = useAppDispatch();

    const handleClick = useCallback(() => {
        dispatch(addProduct(data));

    }, [data, dispatch]);
    return (
        <div className={styles.product}>
            <div className={styles.content}>
                <div className={styles.text}>
                    <h4>{data.name}</h4>
                    <span>{data.description}</span>
                    <span>{data.quantity}</span>
                </div>

                <img src={StorageService.getUrl(data.idImage)} alt={data.name}/>
            </div>
            <div className={styles.actions}>
                <span>{formatPriceAmount(data.price)}</span>
                <Button className={styles.button}
                    label="Adicionar" onClick={handleClick}
                    suffix={<ShoppingBagIcon className={styles.icon} height={24} width={24}/>}
                />
            </div>
        </div>
    );
};
