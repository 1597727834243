import { FunctionComponent } from 'react';
import { DeliveryMethodEnum, Order, StatusEnum } from '../../../types/Order';
import styles from './status-road-map.module.scss';
import { formatStatus } from '../../../utils/formatters';

export const sortedStatus: Record<DeliveryMethodEnum, StatusEnum[]> = {
    [DeliveryMethodEnum.pickup]: [
        StatusEnum.pending,
        StatusEnum.inPreparation,
        StatusEnum.readyToDeliver,
        StatusEnum.delivered,
    ],
    [DeliveryMethodEnum.delivery]: [
        StatusEnum.pending,
        StatusEnum.inPreparation,
        StatusEnum.readyToDeliver,
        StatusEnum.inTransit,
        StatusEnum.delivered,
    ],
};

interface Props {
    order: Order;
    className?: string;
}

export const StatusRoadMap: FunctionComponent<Props> = ({ order, className }) => {
    const allSteps = sortedStatus[order.deliveryMethod];

    const indexActualStep = allSteps.indexOf(order.status);

    const linesPercent = [5, 25, 50, 75, 100];

    return (
        <div className={`${styles.statusRoadMap} ${className}`}>
            {allSteps.map((step, index) => (
                <div
                    key={index}
                    className={`${styles.step} ${index <= indexActualStep ? styles.active : ''}`}>
                    <span data-step={index + 1}>{formatStatus(step, order.deliveryMethod)}</span>
                </div>
            ))}
            <div className={styles.container}>
                <div className={styles.line} style={{ width: `${linesPercent[indexActualStep]}%` }} />
            </div>
        </div>
    );
};
