import { FunctionComponent } from 'react';
import { ErrorIcon } from '../../utils/icon/error';
import { DoneOutlineIcon } from '../../utils/icon/done-outline';
import { formatPriceAmount } from '../../../utils/formatters';
import BoardIcon from '../../../assets/images/board.png';
import styles from './minimum-purchase.module.scss';
import { useMinimumPurchase } from '../../../hooks/useMinimumPurchase';

export const MinimumPurchaseBadge: FunctionComponent = () => {
    const [isValid, minValue] = useMinimumPurchase();
    const errorStyle = isValid ? styles.valid : styles.invalid;

    return (
        <div className={`${styles.minimumPurchaseBadge} ${errorStyle}`}>
            <div>
                <img src={BoardIcon} className={styles.leadingIcon} alt="Board Icon" />
                <span>Valor minimo</span>
            </div>
            <div>
                {!isValid && (<ErrorIcon className={styles.icon} />)}
                {isValid && (<DoneOutlineIcon className={styles.icon} />)}
                <span>{formatPriceAmount(minValue)}</span>
            </div>
        </div>
    );
};
