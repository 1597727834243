import { Action, configureStore, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import app, { setupAppState } from './appSlice';
import auth, { loginFromStorage } from './authSlice';
import order, { setupOrderState } from './orderSlice';
import product, { setupProductState } from './productSlice';
import client, { setupClientState } from './clientSlice';
import address, { setupAddressState } from './addressSlice';
import settings, { setupSettingsState } from './settingsSlice';
import cart, { setupCartState } from './cartSlice';
import discountCoupon from './discountCouponSlice';
import payment, { setupPaymentState } from './paymentSlice';
import notification from './notificationSlice';
import { delay } from '../utils';
import { Api } from '../services/Api';
import { Websocket } from '../services/Websocket';

export const store = configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
    reducer: {
        app,
        auth,
        client,
        order,
        product,
        address,
        cart,
        settings,
        payment,
        discountCoupon,
        notification
    },
});

export type AppThunkDispatcher = ThunkDispatch<RootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

export const setupApplication = async (): Promise<void> => {
    Api.setDispatcher(store.dispatch);

    store.dispatch(setupAppState());
    await delay(400);

    store.dispatch(loginFromStorage());
    store.dispatch(setupProductState());
    store.dispatch(setupOrderState());
    store.dispatch(setupClientState());
    store.dispatch(setupAddressState());
    store.dispatch(setupCartState());
    store.dispatch(setupSettingsState());
    store.dispatch(setupPaymentState());

    await delay(100);

    await Websocket.openWebsocketConnection();
};
