import {useAppDispatch, useAppSelector} from '../../../hooks';
import {getProductsInCart, selectCartProduct} from '../../../store/cartSlice';
import styles from './products-checkout.module.scss';
import {formatPriceAmount} from '../../../utils/formatters';
import {EditIcon} from "../../utils/icon/edit";
import {useCallback} from "react";
import {OrderProduct} from "../../../types/Order";

export const ProductsCheckout = () => {
    const lst = useAppSelector(getProductsInCart);
    const dispatch = useAppDispatch();

    const editItem = useCallback((data: OrderProduct) => {
        dispatch(selectCartProduct(data));
    }, [lst, dispatch]);

    return (
        <div className={styles.productsCheckout}>
            {lst.map(p => {
                const price = p.product.price * p.quantity;
                return (
                    <div key={p.product.id} className={styles.product}>
                        <span>{p.quantity}x {p.product.name}</span>
                        <div className={styles.price}>
                            <span>{formatPriceAmount(price)} </span>
                            <EditIcon className={styles.icon} onClick={() => editItem(p)}/>
                        </div>
                        <span className={styles.secondLine}>{p.observation}</span>
                    </div>
                );
            })}
        </div>
    );
};
