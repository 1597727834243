import { FunctionComponent, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
    chooseNonCardPaymentMethod,
    choosePaymentCard,
    deleteCard,
    getChosenPaymentMethod,
    getPaymentCards,
    getSelectedCardId
} from '../../../store/paymentSlice';
import { getChosenDeliveryMethod } from '../../../store/addressSlice';
import styles from './payment-info.module.scss';
import { DeliveryMethodEnum, PaymentMethodEnum } from '../../../types/Order';
import PixIcon from '../../../assets/images/pix.png';
import PayOnDeliverIcon from '../../../assets/images/pay-on-deliver.png';
import HandCardImage from '../../../assets/images/hand-card.png';
import { OutlineButton } from '../../utils/outline-button';
import { setAddCreditCardIsOpened } from '../../../store/appSlice';

import Mastercard from '../../../assets/images/mastercard.png';
import VisaCard from '../../../assets/images/visacard.png';
import AmexCard from '../../../assets/images/amexcard.png';
import DinersClubCard from '../../../assets/images/diners-clubcard.png';
import DiscoverCard from '../../../assets/images/discovercard.png';
import MaestroCard from '../../../assets/images/maestrocard.png';
import EloCard from '../../../assets/images/elocard.png';
import HiperCard from '../../../assets/images/hipercard.jpg';
import { TrashIcon } from '../../utils/icon/trash';


const icons: Record<string, string> = {
    'visa': VisaCard,
    'mastercard': Mastercard,
    'american-express': AmexCard,
    'diners-club': DinersClubCard,
    'discover': DiscoverCard,
    'maestro': MaestroCard,
    'elo': EloCard,
    'hiper': HiperCard,
    'hipercard': HiperCard,
};

interface PaymentOption {
    icon: string;
    label: string;
    secondLine: string;
    value: PaymentMethodEnum;
    creditCardId?: string;
}

export const PaymentInfo: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const paymentCards = useAppSelector(getPaymentCards);

    const openAddCreditCard = useCallback(() => {
        dispatch(setAddCreditCardIsOpened(true));
    }, [dispatch]);

    const options: PaymentOption[] = [
        {
            icon: PixIcon,
            label: 'Pague com Pix',
            secondLine: 'Use o QR Code ou copie e cole o código',
            value: PaymentMethodEnum.pix
        },
        {
            icon: PayOnDeliverIcon,
            label: 'Pague na entrega',
            secondLine: 'Voce pode pagar na entrega',
            value: PaymentMethodEnum.cash
        },
        ...paymentCards.map(card => ({
            icon: icons[card.brand]!,
            label: `Cartão de Crédito ${card.brand}`,
            secondLine: `**** **** **** ${card.number}`,
            value: PaymentMethodEnum.creditCard,
            creditCardId: card.id
        }))
    ];

    return (
        <div className={styles.paymentInfo}>
            <h2>Escolha a forma de pagamento</h2>

            <div className={styles.options}>
                {options.map(option => (
                    <PaymentOtion option={option} key={option.creditCardId || option.value} />
                ))}
            </div>

            <div className={styles.newCardSection}>
                <div>
                    <h3>Adicione um cartão</h3>
                    <span>É prático, seguro e é bem mais rápido</span>
                    <OutlineButton label="Adicione um cartão" onClick={openAddCreditCard} />
                </div>
                <div>
                    <img src={HandCardImage} alt="Adicione um cartão" />
                </div>
            </div>
        </div>
    );
};

const PaymentOtion: FunctionComponent<{ option: PaymentOption }> = ({ option }) => {

    const dispatch = useAppDispatch();

    const removeCreditCard = useCallback((creditCardId: string) => () => {
        dispatch(deleteCard(creditCardId));
    }, [dispatch]);

    const chosenPaymentMethod = useAppSelector(getChosenPaymentMethod);
    const chosenDeliveryMethod = useAppSelector(getChosenDeliveryMethod);
    const selectedCardId = useAppSelector(getSelectedCardId);

    const handleClickOption = useCallback((option: PaymentOption) => () => {
        if (chosenDeliveryMethod === DeliveryMethodEnum.pickup && option.value === PaymentMethodEnum.cash) {
            return;
        }

        if (!!option.creditCardId) {
            dispatch(choosePaymentCard(option.creditCardId));
            return;
        }

        dispatch(chooseNonCardPaymentMethod(option.value));
    }, [chosenDeliveryMethod, dispatch]);

    const isCreditCardSelected = chosenPaymentMethod === PaymentMethodEnum.creditCard && option.creditCardId === selectedCardId;
    const selectedStyle = isCreditCardSelected || (chosenPaymentMethod === option.value && !selectedCardId) ? styles.selected : '';
    const disabledStyle = chosenDeliveryMethod === DeliveryMethodEnum.pickup && option.value === PaymentMethodEnum.cash ? styles.disabled : '';

    return (
        <div className={`${styles.option} ${selectedStyle} ${disabledStyle}`}>
            <img src={option.icon} alt={option.label} onClick={handleClickOption(option)} />
            <div onClick={handleClickOption(option)}>
                <span>{option.label}</span>
                <span>{option.secondLine}</span>
            </div>
            {option.creditCardId && (
                <TrashIcon className={styles.trashIcon} onClick={removeCreditCard(option.creditCardId)} />)}
        </div>
    );
};
