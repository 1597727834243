import { FunctionComponent } from 'react';
import { IconProps } from '../../../types';

export const CheckIcon: FunctionComponent<IconProps> = props => {
    return (
        <svg
            onClick={props.onClick}
            className={!!props.onClick ? `${props.className} clickable-icon` : props.className}
            xmlns="http://www.w3.org/2000/svg"
            height={props.height || 24}
            viewBox="0 -960 960 960"
            width={props.width || 24}>
            <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
        </svg>
    );
};
