import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Button } from '../../utils/button';
import { createOrder, getOrderIsLoading } from '../../../store/orderSlice';
import { getChosenPaymentMethod, getSelectedCardId } from '../../../store/paymentSlice';
import { getChosenAddress, getChosenDeliveryMethod } from '../../../store/addressSlice';
import { cleanDiscountCouponReservation, getDiscountCouponReservation } from '../../../store/discountCouponSlice';
import { cleanShoppingCart, getProductsInCart } from '../../../store/cartSlice';
import { CreateOrderDto, DeliveryMethodEnum, PaymentMethodEnum } from '../../../types/Order';
import { useStoreIsOpen } from '../../../hooks/useStoreIsOpen';
import { useMinimumPurchase } from '../../../hooks/useMinimumPurchase';
import styles from './create-order-button.module.scss';
import { useNavigate } from 'react-router-dom';

export const CreateOrderButton = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const isLoading = useAppSelector(getOrderIsLoading);

    const paymentMethod = useAppSelector(getChosenPaymentMethod);
    const selectedCardId = useAppSelector(getSelectedCardId);
    const address = useAppSelector(getChosenAddress);
    const deliveryMethod = useAppSelector(getChosenDeliveryMethod);
    const coupon = useAppSelector(getDiscountCouponReservation);
    const products = useAppSelector(getProductsInCart);

    const [storeIsOpen, whenWillOpen] = useStoreIsOpen();
    const [minimumPurchaseValid, minimumPurchaseValue] = useMinimumPurchase();

    const btnDisabled = !products.length
        || !storeIsOpen
        || !minimumPurchaseValid
        || !deliveryMethod
        || (deliveryMethod === DeliveryMethodEnum.delivery && !address)
        || !paymentMethod
        || (paymentMethod === PaymentMethodEnum.creditCard && !selectedCardId);

    const handleClick = useCallback(async () => {
        if (btnDisabled)
            return;

        const dto: CreateOrderDto = {
            paymentMethod,
            products: products.map(({ quantity, option, observation, product }) => ({
                productId: product.id,
                quantity,
                option,
                observation
            })),
            address: deliveryMethod === DeliveryMethodEnum.delivery ? {
                streetLine: address!.streetLine,
                number: address!.number,
                postalCode: address!.postalCode,
                complement: address!.complement,
                city: address!.city,
                neighborhood: address!.neighborhood,
                state: address!.state,
                lat: address!.lat,
                lng: address!.lng,
            } : undefined,
            paymentCardId: paymentMethod === PaymentMethodEnum.creditCard ? selectedCardId! : undefined,
            deliveryMethod,
            reservationDiscountCouponId: coupon?.id
        };

        try {
            await dispatch(createOrder(dto));
            dispatch(cleanShoppingCart());
            dispatch(cleanDiscountCouponReservation());
            navigate('/order');
        } catch (err) {
            console.log(err);
        }

    }, [address, btnDisabled, coupon?.id, deliveryMethod, dispatch, navigate, paymentMethod, products, selectedCardId]);

    return (
        <div className={styles.createOrderButton}>
            {!storeIsOpen && (
                <span className={styles.errorMessage}>A loja esta fechada. Abriremos {whenWillOpen}</span>
            )}
            {!minimumPurchaseValid && (
                <span className={styles.errorMessage}>O valor minimo para pedido é de R$ {minimumPurchaseValue}</span>
            )}
            <Button label="Fazer Pedido" onClick={handleClick} disabled={btnDisabled} isLoading={isLoading} />
        </div>
    );
};
