import { AddressInfo, AddressWithLabel } from '../types/Address';
import { DeliveryMethodEnum } from '../types/Order';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetStateFn, LatLng } from '../types';
import { delay, getAddressId, isSameAddress, removeFromListCustom, upsertOnListCustom } from '../utils';
import { removeOnLocalStorage, retrieveFromLocalStorage, saveOnLocalStorage } from '../utils/storage';
import { AppThunk, AppThunkDispatcher, RootState } from './index';
import { LocationService } from '../services/LocationService';
import { SearchLocationResponse, SearchLocationResponseItem } from "../types/location";
import { Websocket } from "../services/Websocket";
import { setChooseDeliveryPopupIsOpened } from "./appSlice";
import { Client } from '../types/Client';

import ClientService from '../services/ClientService';

const DELIVERY_METHOD_KEY = 'delivery-method';
const CHOSEN_ADDRESS_KEY = 'chosen-address';
const ALL_ADDRESSES_KEY = 'all-addresses';

interface AddressState {
    addresses: AddressWithLabel[];
    addressesOutOFDeliveryZone: AddressWithLabel[];
    deliveryMethod: DeliveryMethodEnum | null;
    chosenAddress: AddressWithLabel | null;
    myLocationAddress: AddressWithLabel | null;
    myLocationIsOutOFDeliveryZone: boolean;
    myLocationIsLoading: boolean;
    searchId: string | null;
    searchResponses: SearchLocationResponseItem[];
    searchIsLoading: boolean;
    placesOutOFDeliveryZone: string[];
    fetchLocationIsLoading: boolean;
    addressInEdition: AddressWithLabel | null;
}

const initialState: AddressState = {
    addresses: [],
    addressesOutOFDeliveryZone: [],
    deliveryMethod: DeliveryMethodEnum.pickup,
    chosenAddress: null,
    myLocationAddress: null,
    myLocationIsOutOFDeliveryZone: false,
    myLocationIsLoading: false,
    searchId: null,
    searchResponses: [],
    searchIsLoading: false,
    placesOutOFDeliveryZone: [],
    fetchLocationIsLoading: false,
    addressInEdition: null
};

export const addressSlice = createSlice({
    name: 'address',
    initialState,
    reducers: {
        upsertAddress: (state, action: PayloadAction<AddressWithLabel>) => {
            state.addresses = upsertOnListCustom(state.addresses, action.payload, getAddressId);
        },
        deleteAddressFromList: (state, action: PayloadAction<AddressWithLabel>) => {
            state.addresses = removeFromListCustom(state.addresses, action.payload, getAddressId);
        },
        updateChosenAddress: (state, action: PayloadAction<AddressWithLabel | null>) => {
            state.chosenAddress = action.payload;
        },
        updateAddressesOutsideDeliveryZone: (state, action: PayloadAction<AddressWithLabel[]>) => {
            state.addressesOutOFDeliveryZone = action.payload;
        },
        updateAddressList: (state, action: PayloadAction<AddressWithLabel[]>) => {
            state.addresses = action.payload;
        },
        setDeliveryMethod: (state, action: PayloadAction<DeliveryMethodEnum>) => {
            state.deliveryMethod = action.payload;
        },
        setMyLocationIsOutOFDeliveryZone: (state, action: PayloadAction<boolean>) => {
            state.myLocationIsOutOFDeliveryZone = action.payload;
        },
        setMyLocationIsLoading: (state, action: PayloadAction<boolean>) => {
            state.myLocationIsLoading = action.payload;
        },
        setMyLocationAddress: (state, action: PayloadAction<AddressWithLabel>) => {
            state.myLocationAddress = action.payload;
        },
        setSearchResponse: (state, action: PayloadAction<SearchLocationResponse>) => {
            state.searchResponses = action.payload.responses;
            state.searchId = action.payload.searchId;
        },
        setSearchIsLoading: (state, action: PayloadAction<boolean>) => {
            state.searchIsLoading = action.payload;
        },
        insertPlaceOutOFDeliveryZone: (state, action: PayloadAction<string>) => {
            state.placesOutOFDeliveryZone = [...state.placesOutOFDeliveryZone, action.payload];
        },
        cleanSearch: state => {
            state.searchId = null;
            state.searchResponses = [];
        },
        setFetchLocationIsLoading: (state, action: PayloadAction<boolean>) => {
            state.fetchLocationIsLoading = action.payload;
        },
        updateAddressInEdition: (state, action: PayloadAction<AddressWithLabel | null>) => {
            state.addressInEdition = action.payload;
        },
        cleanAddressInEdition: state => {
            state.addressInEdition = null;
        },
        cleanState: () => initialState,
        cleanAddressWithoutId: state => {
            state.addresses = state.addresses.filter(x => !!x.id)
        }
    }
});

export const {
    setDeliveryMethod,
    setMyLocationAddress,
    upsertAddress,
    setSearchResponse,
    cleanSearch,
    setSearchIsLoading,
    setFetchLocationIsLoading,
    updateAddressList,
    setMyLocationIsOutOFDeliveryZone,
    insertPlaceOutOFDeliveryZone,
    cleanState,
    updateAddressesOutsideDeliveryZone,
    updateChosenAddress,
    deleteAddressFromList,
    updateAddressInEdition,
    cleanAddressInEdition,
    cleanAddressWithoutId,
    setMyLocationIsLoading
} = addressSlice.actions;

export const searchLocations = (query: string): AppThunk => (dispatch, getState) => {
    const searchId = getState().address.searchId;
    dispatch(setSearchIsLoading(true))

    return LocationService.searchLocation(query, searchId)
        .then(response => {
            dispatch(setSearchResponse(response));
            dispatch(setSearchIsLoading(false));
        })
        .catch(err => {
            console.log(err);
            dispatch(setSearchIsLoading(false));
        });
}

const fetchShippingPrice = (address: AddressInfo): Promise<AddressWithLabel> =>
    LocationService.getShippingPrice(address.lat, address.lng)
        .then(shippingPrice => ({ ...address, shippingPrice }));

const addNewAddressAndEditIt = (address: AddressWithLabel): AppThunk => async (dispatch, getState) => {
    dispatch(updateAddressInEdition(address))
    return updateOnStorage(getState);
}

export const chooseSearchResult = (result: SearchLocationResponseItem): AppThunk<Promise<void>> => async (dispatch, getState) => {
    const searchId = getState().address.searchId;
    dispatch(setFetchLocationIsLoading(true));

    return LocationService.getAddress(result.placeId, searchId!)
        .then(fetchShippingPrice)
        .then(address => {
            dispatch(addNewAddressAndEditIt(address));
            dispatch(cleanSearch());
            dispatch(setFetchLocationIsLoading(false));
        })
        .catch(err => {
            dispatch(setFetchLocationIsLoading(false));

            if (err.message === 'out-of-work-area') {
                dispatch(insertPlaceOutOFDeliveryZone(result.placeId));
            }

            throw err;
        });
}

export const chooseDeliveryMethod = (deliveryMethod: DeliveryMethodEnum): AppThunk => async (dispatch, getState) => {
    const address = getChosenAddress(getState());

    if (deliveryMethod === DeliveryMethodEnum.delivery && !address) {
        dispatch(setChooseDeliveryPopupIsOpened(true));
        return;
    }

    dispatch(setDeliveryMethod(deliveryMethod));
    await updateOnStorage(getState);
};

export const setupAddressState = (): AppThunk => (dispatch, getState) => {
    loadFromStorage(dispatch);
    dispatch(updateAddressesShippingPriceAndOutsideDeliveryZone());

    Websocket.onEvent<undefined>('areas-was-updated', () => {
        dispatch(updateAddressesShippingPriceAndOutsideDeliveryZone());
    });

    Websocket.onEvent<Client>('update-client', (client: Client) => {
        const addressList: AddressWithLabel[] = client.addresses.map(x => ({
            id: x.id,
            description: x.description,
            shippingPrice: 999,
            ...x.info
        }));

        dispatch(updateAddressList(addressList));
        dispatch(updateAddressesShippingPriceAndOutsideDeliveryZone());

        const chosenAddress = getState().address.chosenAddress;
        const updatedChosenAddress = addressList.find(x => isSameAddress(x, chosenAddress));

        if (!updatedChosenAddress) {
            chooseNextAvailableAddress(dispatch, getState)
            return;
        }

        dispatch(updateChosenAddress(updatedChosenAddress));
    });

    Websocket.executeOnLogin(async () => {
        const promises = getState().address.addresses
            .filter(x => !x.id)
            .map(ClientService.createAddress);

        await Promise.all(promises);

        dispatch(cleanAddressWithoutId());
        return updateOnStorage(getState);
    });
};

export const saveNewAddress = (address: AddressWithLabel): AppThunk => async (dispatch, getState) => {
    dispatch(upsertAddress(address));
    dispatch(updateChosenAddress(address));
    dispatch(cleanAddressInEdition());
    dispatch(setDeliveryMethod(DeliveryMethodEnum.delivery));

    if (getState().auth.loggedUser) {
        await ClientService.createAddress(address)
    }

    return updateOnStorage(getState);
}

export const chooseNextAvailableAddress = (dispatch: AppThunkDispatcher, getState: GetStateFn) => {
    const addresses = getState().address.addresses;
    const chosenAddress = getState().address.chosenAddress;
    const addressOutOFDeliveryZone = getState().address.addressesOutOFDeliveryZone;

    if (!chosenAddress || (addresses.includes(chosenAddress) && !addressOutOFDeliveryZone.includes(chosenAddress)))
        return;

    const firstOrNullInsideDeliveryZone = addresses.find(x => !addressOutOFDeliveryZone.includes(x));
    dispatch(updateChosenAddress(firstOrNullInsideDeliveryZone || null));

    if (!!firstOrNullInsideDeliveryZone)
        return;

    dispatch(setDeliveryMethod(DeliveryMethodEnum.pickup));
}

export const removeAddress = (address: AddressWithLabel): AppThunk => async (dispatch, getState) => {
    dispatch(deleteAddressFromList(address));
    chooseNextAvailableAddress(dispatch, getState);

    if (getState().auth.loggedUser)
        await ClientService.deleteAddress(address.id!)

    return updateOnStorage(getState);
}

export const getAddressFromMyLocation = (latLng: LatLng): AppThunk<Promise<void>> => async dispatch => {
    dispatch(setMyLocationIsLoading(true));

    return LocationService.getAddressByLatLng(latLng[0], latLng[1])
        .then(fetchShippingPrice)
        .then(address => {
            dispatch(addNewAddressAndEditIt(address));
            dispatch(setMyLocationAddress(address));
            dispatch(setMyLocationIsLoading(false));
            dispatch(setMyLocationIsOutOFDeliveryZone(false));
        })
        .catch((err) => {
            dispatch(setMyLocationIsLoading(false));

            if (err.message === 'out-of-work-area') {
                dispatch(setMyLocationIsOutOFDeliveryZone(true));
            }

            throw err;
        });
};

const updateOnStorage = async (getState: GetStateFn): Promise<void> => {
    await delay(100);

    const state = getState().address;
    if (state.deliveryMethod) {
        saveOnLocalStorage(DELIVERY_METHOD_KEY, state.deliveryMethod);
    }

    if (state.chosenAddress) {
        saveOnLocalStorage(CHOSEN_ADDRESS_KEY, state.chosenAddress);
    }

    if (state.addresses.length > 0) {
        saveOnLocalStorage(ALL_ADDRESSES_KEY, state.addresses);
    }
};

export const cleanAddressStateAndStorage = (): AppThunk => dispatch => {
    dispatch(cleanState());

    removeOnLocalStorage(DELIVERY_METHOD_KEY);
    removeOnLocalStorage(CHOSEN_ADDRESS_KEY);
    removeOnLocalStorage(ALL_ADDRESSES_KEY);
}

const loadFromStorage = (dispatch: AppThunkDispatcher): void => {
    const deliveryMethod = retrieveFromLocalStorage<DeliveryMethodEnum>(DELIVERY_METHOD_KEY);
    const address = retrieveFromLocalStorage<AddressWithLabel>(CHOSEN_ADDRESS_KEY);
    const addresses = retrieveFromLocalStorage<AddressWithLabel[]>(ALL_ADDRESSES_KEY);

    if (deliveryMethod) {
        dispatch(setDeliveryMethod(deliveryMethod));
    }

    if (address) {
        dispatch(updateChosenAddress(address));
    }

    if (addresses) {
        dispatch(updateAddressList(addresses));
    }
};

const updateAddressesShippingPriceAndOutsideDeliveryZone = (): AppThunk => async (dispatch, getState): Promise<void> => {
    const addresses = getState().address.addresses;

    let lstOutside: AddressWithLabel[] = [];
    for (const address of addresses) {
        try {
            const shippingPrice = await LocationService.getShippingPrice(address.lat, address.lng);
            dispatch(upsertAddress({ ...address, shippingPrice }));
        } catch (err: any) {
            if (err.message !== 'out-of-work-area') {
                throw err
            }

            lstOutside.push(address);
        }
    }

    dispatch(updateAddressesOutsideDeliveryZone(lstOutside));
    chooseNextAvailableAddress(dispatch, getState);

    await updateOnStorage(getState);
}

export const getChosenDeliveryMethod = (root: RootState): DeliveryMethodEnum | null => root.address.deliveryMethod;
export const getChosenAddress = (root: RootState): AddressWithLabel | null => root.address.chosenAddress;

export const getAddressInEdition = (root: RootState): AddressWithLabel | null => root.address.addressInEdition;
export const getAddresses = (root: RootState): AddressWithLabel[] => root.address.addresses;
export const getAddressesOutOFDeliveryZone = (root: RootState): AddressWithLabel[] => root.address.addressesOutOFDeliveryZone;
export const getShippingPrice = (root: RootState): number => {
    const { deliveryMethod, chosenAddress } = root.address;

    if (deliveryMethod !== DeliveryMethodEnum.delivery || !chosenAddress)
        return 0;

    return chosenAddress.shippingPrice;
}

export const getMyLocationIsLoading = (root: RootState): boolean => root.address.myLocationIsLoading;
export const getMyLocationAddress = (root: RootState): AddressWithLabel | null => root.address.myLocationAddress;
export const getMyLocationAddressIsOutsideDeliveryZone = (root: RootState): boolean => root.address.myLocationIsOutOFDeliveryZone;

export const getSearchResponses = (root: RootState): SearchLocationResponseItem[] => root.address.searchResponses;
export const getSearchIsLoading = (root: RootState): boolean => root.address.searchIsLoading;
export const getFetchLocationIsLoading = (root: RootState): boolean => root.address.fetchLocationIsLoading;
export const getPlacesOutOFDeliveryZone = (root: RootState): string[] => root.address.placesOutOFDeliveryZone;

export default addressSlice.reducer;
