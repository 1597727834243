import { FunctionComponent } from "react";
import styles from './text-button.module.scss';

interface Props {
    label: string;
    onClick: () => void;
    className?: string;
}

export const TextButton: FunctionComponent<Props> = props => {
    return (
        <div className={`${styles.textButton} ${props.className}`} onClick={props.onClick}>{props.label}</div>
    )
}