import { Field } from '../../utils/field';
import styles from './discount-coupon-field.module.scss';
import { FunctionComponent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
    applyCoupon,
    getDiscountCouponErrorReservation,
    getDiscountCouponIsLoading,
    getDiscountCouponReservation
} from '../../../store/discountCouponSlice';
import { getLoggedUser } from '../../../store/authSlice';
import { SpinLoading } from '../../utils/spin-loading';
import { DoneOutlineIcon } from '../../utils/icon/done-outline';

export const DiscountCouponField: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const loggedUser = useAppSelector(getLoggedUser);
    const isLoading = useAppSelector(getDiscountCouponIsLoading);
    const discountCoupon = useAppSelector(getDiscountCouponReservation);
    const error = useAppSelector(getDiscountCouponErrorReservation);

    const [couponKey, setCouponKey] = useState<string>('');

    useEffect(() => {
        const typingTimer = setTimeout(() => {
            if (!couponKey || couponKey.length < 3)
                return;
            dispatch(applyCoupon(couponKey));
        }, 500);

        return () => clearTimeout(typingTimer);
    }, [dispatch, couponKey]);

    if (!loggedUser)
        return null;

    const loadingSuffix = isLoading ? <SpinLoading/> : undefined;

    const errorStyle = !!error ? styles.error : undefined;
    const successStyle = !!discountCoupon ? styles.success : undefined;

    if (!!discountCoupon)
        return (
            <div className={`${styles.discountCouponField} ${successStyle}`}>
                <span>Cupom de desconto aplicado com sucesso!</span>
                <DoneOutlineIcon className={styles.icon}/>
                <span>{couponKey}</span>
            </div>
        );

    return (
        <div className={`${styles.discountCouponField} ${errorStyle}`}>
            <span>Você tem um cupom de desconto?</span>
            <Field placeholder="Digite aqui o código" onChange={setCouponKey} value={couponKey} suffix={loadingSuffix}/>
            {!!error && (
                <span className={styles.errorLabel}>{error}</span>
            )}
        </div>
    );
};