import { FunctionComponent } from 'react';
import { FieldMasked } from '../field-masked';
import { extractNumber } from '../../../utils';

interface Props {
    value: string;
    onChange: (val: string) => void;
    className?: string;
}

export const cpfInputFormatter = (cpf: string | null | undefined): string => {
    if (!cpf) return '';

    const slice = (start: number, end: number): string => cpf.slice(start, end);
    const firstBloc = slice(0, 3);
    const secondBloc = slice(3, 6);
    const thirdBloc = slice(6, 9);
    const fourthBloc = slice(9, 11);

    if (!!fourthBloc.length)
        return `${firstBloc}.${secondBloc}.${thirdBloc}-${fourthBloc}`;

    if (!!thirdBloc.length)
        return `${firstBloc}.${secondBloc}.${thirdBloc}`;

    if (!!secondBloc.length)
        return `${firstBloc}.${secondBloc}`;

    return firstBloc;
};

export const validCpf = function (cpf: string): boolean {
    if (
        !cpf ||
        cpf.length !== 11 ||
        cpf === '00000000000' ||
        cpf === '11111111111' ||
        cpf === '22222222222' ||
        cpf === '33333333333' ||
        cpf === '44444444444' ||
        cpf === '55555555555' ||
        cpf === '66666666666' ||
        cpf === '77777777777' ||
        cpf === '88888888888' ||
        cpf === '99999999999'
    )
        return false;
    let soma = 0;
    let resto;
    for (let i = 1; i <= 9; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10))) return false;
    soma = 0;
    for (let i = 1; i <= 10; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(10, 11))) return false;
    return true;
};

export const FieldCpf: FunctionComponent<Props> = props =>
    <FieldMasked
        {...props}
        label="CPF"
        placeHolder="070.680.938-68"
        formatter={cpfInputFormatter}
        validator={validCpf} />;
