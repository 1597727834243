import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from './index';
import { Api } from '../services/Api';
import { cleanAuthStorageAndLogoff, logoff } from './authSlice';
import { cleanProductsStateAndStorage } from './productSlice';
import { cleanOrderStateAndStorage } from './orderSlice';
import { cleanClientStateAndStorage } from './clientSlice';
import { cleanSettingsStateAndStorage } from './settingsSlice';
import { cleanAddressStateAndStorage } from './addressSlice';
import { cleanCartStateAndStorage } from './cartSlice';

interface AppState {
    hasConnection: boolean;
    chooseDeliveryPopupIsOpened: boolean;
    cartIsOpened: boolean;
    storeOpenedChangeStatePopup: boolean;
    addCreditCardIsOpened: boolean;
}

const initialState: AppState = {
    hasConnection: false,
    chooseDeliveryPopupIsOpened: false,
    cartIsOpened: false,
    storeOpenedChangeStatePopup: false,
    addCreditCardIsOpened: false
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setHasConnection: (state, action: PayloadAction<boolean>) => {
            state.hasConnection = action.payload;
        },
        setChooseDeliveryPopupIsOpened: (state, action: PayloadAction<boolean>) => {
            state.chooseDeliveryPopupIsOpened = action.payload;
        },
        setCartIsOpened: (state, action: PayloadAction<boolean>) => {
            state.cartIsOpened = action.payload;
        },
        setStoreOpenedChangeStatusPopup: (state, action: PayloadAction<boolean>) => {
            state.storeOpenedChangeStatePopup = action.payload;
        },
        setAddCreditCardIsOpened: (state, action: PayloadAction<boolean>) => {
            state.addCreditCardIsOpened = action.payload;
        },
        resetState: () => initialState,
    }
});

export const {
    setHasConnection,
    setChooseDeliveryPopupIsOpened,
    resetState,
    setCartIsOpened,
    setStoreOpenedChangeStatusPopup,
    setAddCreditCardIsOpened
} = appSlice.actions;


export const getChooseDeliveryIsOpened = (state: RootState): boolean => state.app.chooseDeliveryPopupIsOpened;
export const getCartIsOpened = (state: RootState): boolean => state.app.cartIsOpened;
export const getStoreOpenedChangeStatusPopup = (state: RootState): boolean => state.app.storeOpenedChangeStatePopup;
export const getAddCreditCardIsOpened = (state: RootState): boolean => state.app.addCreditCardIsOpened;

export const setupAppState = (): AppThunk => async dispatch => {
    const verifyHasConnection = () =>
        Api.hasConnectionToServer()
            .then(conn => dispatch(setHasConnection(conn)));

    verifyHasConnection()
        .then(() => setInterval(verifyHasConnection, 5 * 1000));
};

export const cleanLocalStorageAndLogoff = (): AppThunk<Promise<void>> => async dispatch => {
    dispatch(resetState());
    dispatch(cleanProductsStateAndStorage());
    dispatch(cleanOrderStateAndStorage());
    dispatch(cleanAuthStorageAndLogoff());
    dispatch(cleanClientStateAndStorage());
    dispatch(cleanAddressStateAndStorage());
    dispatch(cleanCartStateAndStorage());
    dispatch(cleanSettingsStateAndStorage());

    await dispatch(logoff());
};

export default appSlice.reducer;
