import { FunctionComponent, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getChooseDeliveryIsOpened, setChooseDeliveryPopupIsOpened } from '../../store/appSlice';
import styles from './choose-address.module.scss';
import { ChooseCustomAddress } from './custom-address';
import { Dialog } from '../utils/dialog';
import { useStepper } from '../../hooks/useStepper';
import { EditAddress } from './edit-address';
import {
    saveNewAddress,
    cleanAddressInEdition,
    getAddresses,
    getAddressesOutOFDeliveryZone, getAddressInEdition,
    getChosenAddress,
    getChosenDeliveryMethod,
    removeAddress, updateChosenAddress,
} from '../../store/addressSlice';
import { ArrowBackIcon } from '../utils/icon/arrow-back';
import { AddressWithLabel } from '../../types/Address';
import { VoidCallback } from '../../types';
import { AddressOption } from './address-option';
import { formatAddress, formatFullAddress, formatSecondLineAddress } from '../../utils/formatters';
import { PinLocationIcon } from '../utils/icon/pin-location';
import { getAddressId } from '../../utils';
import { DeliveryMethodEnum } from '../../types/Order';

enum StepsCustomAddress {
    CHOOSE_ADDRESS,
    CONFIRM_ADDRESS
}

export const ChooseDeliveryMethod: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const [step, increaseStep, decreaseStep, setStep] = useStepper();

    const isOpen = useAppSelector(getChooseDeliveryIsOpened);
    const addressInEdition = useAppSelector(getAddressInEdition)

    const handleClose = useCallback(() => {
        setStep(0)
        dispatch(setChooseDeliveryPopupIsOpened(false));
        dispatch(cleanAddressInEdition())
    }, [setStep, dispatch]);

    const handleConfirmedAddress = useCallback((address: AddressWithLabel) => {
        dispatch(saveNewAddress(address));
        dispatch(setChooseDeliveryPopupIsOpened(false));
        setStep(0);
    }, [dispatch, setStep]);

    const handleChosenAddress = useCallback((address: AddressWithLabel) => {
        dispatch(updateChosenAddress(address));
        dispatch(setChooseDeliveryPopupIsOpened(false));
        setStep(0);
    },[dispatch, setStep]);

    const handleBack = useCallback(() => {
        decreaseStep()
        dispatch(cleanAddressInEdition())
    }, [decreaseStep, dispatch])

    const backButton = step === StepsCustomAddress.CONFIRM_ADDRESS ?
        <ArrowBackIcon onClick={handleBack} className={styles.backButton}/> : undefined;

    const Component: FunctionComponent = () => {
        if (step === StepsCustomAddress.CONFIRM_ADDRESS)
            return <EditAddress address={addressInEdition!} onSave={handleConfirmedAddress}/>;

        return <AddressOptions requestToConfirm={increaseStep} onChosen={handleChosenAddress}/>;
    };

    const title = {
        [StepsCustomAddress.CHOOSE_ADDRESS]: 'Onde quer receber?',
        [StepsCustomAddress.CONFIRM_ADDRESS]: 'Confirme o endereço'
    }[step]!;

    const styleChooseDelivery = step === StepsCustomAddress.CONFIRM_ADDRESS ? '' : styles.fixedHeight;

    return (
        <Dialog
            className={`${styles.chooseDeliveryMethod} ${styleChooseDelivery}` }
            onClose={handleClose}
            title={title}
            leading={backButton}
            isOpen={isOpen}>
            <Component/>
        </Dialog>
    );
};

interface Props {
    requestToConfirm: VoidCallback;
    onChosen: (obj: AddressWithLabel) => void;
}

const AddressOptions: FunctionComponent<Props> = props => {
    const dispatch = useAppDispatch();

    const addresses = useAppSelector(getAddresses);
    const addressesOutOfArea = useAppSelector(getAddressesOutOFDeliveryZone);
    const chosenAddress = useAppSelector(getChosenAddress);
    const deliveryMethod = useAppSelector(getChosenDeliveryMethod);

    const chosenAddressId = getAddressId(chosenAddress);

    const removeFromList = useCallback((address: AddressWithLabel) => () => {
        dispatch(removeAddress(address));
    }, [dispatch]);

    const renderAddress = (address: AddressWithLabel) => {
        const isError = addressesOutOfArea.includes(address);
        if (isError)
            return (
                <AddressOption
                    key={getAddressId(address)}
                    title="Não atendemos mais nesse Endereço"
                    subtitle={formatFullAddress(address)}
                    Icon={PinLocationIcon}
                    isError={true}
                    onRemove={removeFromList(address)}
                />
            );
        return (
            <AddressOption
                key={getAddressId(address)}
                title={address.description || formatAddress(address)}
                subtitle={!!address.description ? formatAddress(address) : formatSecondLineAddress(address)}
                onClick={() => props.onChosen(address)}
                isSelected={isDelivery && getAddressId(address) === chosenAddressId}
                Icon={PinLocationIcon}
                isError={isError}
                onRemove={addresses.length > 1 ? removeFromList(address) : undefined}
            />
        );
    };

    const isDelivery = deliveryMethod === DeliveryMethodEnum.delivery;
    return (
        <>
            <ChooseCustomAddress requestToConfirm={props.requestToConfirm}/>
            {!!addresses.length && (
                <>
                    <h3>Endereços utilizados</h3>
                    {addresses.map(renderAddress)}
                </>
            )}
        </>
    );
};
