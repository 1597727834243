import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { setupApplication, store } from './store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './index.scss';

setupApplication()
    .then(() => console.log('The application was successfully setup'));

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);

serviceWorkerRegistration.register();
