import { FunctionComponent, useCallback } from 'react';
import { AccountIcon } from '../../utils/icon/account';
import { useNavigate } from 'react-router-dom';
import styles from './account-button.module.scss';
import { useAppSelector } from '../../../hooks';
import { getLoggedUser } from '../../../store/authSlice';
import { formatFirstName } from '../../../utils/formatters';

export const AccountButton: FunctionComponent = () => {
    const loggedUser = useAppSelector(getLoggedUser);
    const navigate = useNavigate();

    const open = useCallback(() => {
        navigate('/profile');
    }, [navigate]);

    return (
        <div className={styles.profile} onClick={open}>
            <AccountIcon className={styles.accountIcon}/>
            <span>{formatFirstName(loggedUser) || 'Meu perfil'}</span>
        </div>
    );
};
