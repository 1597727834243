import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from './index';
import { Api } from '../services/Api';
import { Websocket } from '../services/Websocket';
import { delay, userFromToken } from '../utils';
import { BasicUser } from '../types';
import ClientService from '../services/ClientService';
import { LoginRequest } from '../types/LoginRequest';
import { removeOnLocalStorage, retrieveFromLocalStorage, saveOnLocalStorage } from '../utils/storage';
import { CreateClientDto } from '../types/Client';
import { getErrorMessage } from "../utils/errorMessage";

export const TOKEN_KEY = 'token';

export enum LoginStateEnum {
    willDoBasicAuth = 'not-started',
    willDoTwoFactorAuth = 'started'
}

interface AuthState {
    loggedUser: BasicUser | null,
    loginState: LoginStateEnum,
    isLoginLoading: boolean,
    tokenExpiresAt: number | null;
    errorCreateClient: string | null
}

const initialState: AuthState = {
    loggedUser: null,
    loginState: LoginStateEnum.willDoBasicAuth,
    isLoginLoading: false,
    tokenExpiresAt: null,
    errorCreateClient: null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateLoggedUser: (state, action: PayloadAction<BasicUser | null>) => {
            state.loggedUser = action.payload;
        },
        updateStateLogin: (state, action: PayloadAction<LoginStateEnum>) => {
            state.loginState = action.payload;
        },
        updateTokenExpiration: (state, action: PayloadAction<number | null>) => {
            state.tokenExpiresAt = action.payload;
        },
        setIsLoginLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoginLoading = action.payload;
        },
        setErrorCreateClient: (state, action: PayloadAction<string| null>) => {
            state.errorCreateClient = action.payload
        }
    }
});

export const {
    updateLoggedUser,
    updateStateLogin,
    updateTokenExpiration,
    setIsLoginLoading,
    setErrorCreateClient
} = authSlice.actions;

export const renewToken = (): AppThunk<Promise<void>> => () =>
    ClientService.renewToken();

export const sendTwoFactorCode = (phoneNumber: string): AppThunk<Promise<void>> => async dispatch => {
    dispatch(setIsLoginLoading(true));

    await ClientService.sendTwoFA(phoneNumber)
        .then(() => {
            dispatch(updateStateLogin(LoginStateEnum.willDoTwoFactorAuth));
            dispatch(setIsLoginLoading(false));
            dispatch(setErrorCreateClient(null))
        })
        .catch(err => {
            dispatch(setIsLoginLoading(false));
            throw err;
        });
};

export const createClient = (obj: CreateClientDto): AppThunk => (dispatch, getState) => {
    dispatch(setIsLoginLoading(true));

    return ClientService.createClient(obj)
        .then(token => {
            dispatch(updateStateLogin(LoginStateEnum.willDoTwoFactorAuth));
            dispatch(setIsLoginLoading(false));
        })
        .catch(err => {
            console.log('err', err);
            dispatch(setErrorCreateClient(getErrorMessage(err.message)))
            dispatch(setIsLoginLoading(false));
        });
};

export const login = (obj: LoginRequest): AppThunk<Promise<void>> => dispatch => {
    dispatch(setIsLoginLoading(true));

    return ClientService.login(obj)
        .then(token => {
            dispatch(updateToken(token));
            dispatch(setIsLoginLoading(false));
        })
        .catch(err => {
            dispatch(setErrorCreateClient(getErrorMessage(err.message)))
            dispatch(setIsLoginLoading(false));
        });
};

let timeout: NodeJS.Timeout;
export const updateToken = (token: string): AppThunk => async dispatch => {
    saveOnLocalStorage(TOKEN_KEY, token);

    Api.setToken(token);
    Websocket.updateToken(token);

    const user = userFromToken(token)!;

    const expiration = (user as any).exp;
    if (expiration <= Date.now()) {
        dispatch(logoff());
        return;
    }

    clearTimeout(timeout);
    timeout = setTimeout(() => dispatch(logoff()), expiration - Date.now());

    dispatch(updateTokenExpiration((user as any).exp));
    dispatch(updateLoggedUser(user));

    await Websocket.openWebsocketConnection();
};

export const updateOfflineToken = (token: string): AppThunk => dispatch => {
    const user = userFromToken(token)!;
    dispatch(updateLoggedUser(user));
    dispatch(updateTokenExpiration(null));
};

export const loginFromStorage = (): AppThunk => (dispatch, getState) => {
    const token = retrieveFromLocalStorage<string>(TOKEN_KEY);

    if (!token)
        return;

    let hasConnection = getState().app.hasConnection;
    const waitForConnection = async (): Promise<void> => {
        if (hasConnection) {
            return;
        }

        await delay(10 * 1000);
        hasConnection = getState().app.hasConnection;
        return waitForConnection();
    };

    if (!hasConnection)
        dispatch(updateOfflineToken(token));

    waitForConnection()
        .then(() => dispatch(updateToken(token)));
};

export const cleanAuthStorageAndLogoff = (): AppThunk => dispatch => {
    removeOnLocalStorage(TOKEN_KEY);
    dispatch(logoff());
};

export const logoff = (): AppThunk<Promise<void>> => async dispatch => {
    dispatch(updateTokenExpiration(null));
    dispatch(updateLoggedUser(null));
    dispatch(updateStateLogin(LoginStateEnum.willDoBasicAuth));

    removeOnLocalStorage(TOKEN_KEY);

    Api.setToken('');
    await Websocket.updateToken('');
};


export const getLoggedUser = (state: RootState): BasicUser | null => state.auth.loggedUser;
export const getLoginState = (state: RootState): LoginStateEnum => state.auth.loginState;
export const getIsLoginLoading = (state: RootState): boolean => state.auth.isLoginLoading;

export const getErrorCreateClient = (state: RootState) => state.auth.errorCreateClient;

export default authSlice.reducer;
