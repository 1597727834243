import { getUrlGenerator } from '../utils';
import { AddressInfo, PossibleAddress } from '../types/Address';
import { Api } from './Api';
import { SearchLocationResponse } from "../types/location";

const baseUrl = process.env.REACT_APP_LOCATION_API;

const url = getUrlGenerator(baseUrl);

export class LocationService {
    public static getAddressByLatLng = (lat: number, lng: number): Promise<AddressInfo> =>
        Api.post<PossibleAddress>(url('getAddress', lat, lng))
            .then(this.mapAddress);

    public static searchLocation = (query: string, searchId: string | null): Promise<SearchLocationResponse> =>
        Api.post(url('searchLocation'), { query, searchId });

    public static getAddress = (placeId: string, searchId: string): Promise<AddressInfo> =>
        Api.post<PossibleAddress>(url('getAddress'), { placeId, searchId })
            .then(this.mapAddress);

    public static getShippingPrice = (lat: string, lng: string): Promise<number> =>
        Api.get<number>(url('area', 'shippingPrice', lat, lng));

    private static mapAddress = (response: PossibleAddress): AddressInfo => ({
        streetLine: response.addressLine,
        number: response.streetNumber,
        postalCode: response.postalCode,
        city: response.city,
        neighborhood: response.neighborhood,
        state: response.state,
        lat: response.latLng.lat.toString(),
        lng: response.latLng.lng.toString(),
    });
}
